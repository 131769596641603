<template>
  <div
    class="flex flex-col items-center rounded-md pt-6 pb-4 xl:pb-10 px-3 xl:px-7 shadow-md w-full"
    style="min-height: 80vh"
  >
    <div class="flex w-full justify-center">
      <h2
        class="text-3xl text-black dark:text-white pb-4 fugaz uppercase"
      >
        game page Preview
      </h2>
    </div>
    <div class="w-full h-max relative">
      <div
        class="absolute fugaz"
        style="max-width: 400px; bottom: 25%"
        :style="sidebarFixed ? 'left: 11%;' : 'left: 13%;'"
      >
        <h1 class="text-white text-xl sm:text-2xl lg:text-3xl uppercase">GAI HUB</h1>
        <h1 class="text-white text-xl sm:text-2xl lg:text-3xl uppercase">ONLINE GAMES</h1>
        <div v-if="!gameDetails.isDev">
        <button
            class="w-full hidden xl:flex justify-center items-center py-2 sm:py-3 md:py-4 mt-2 bg-pink-600 text-white transition-colors duration-150 xl:hover:bg-purple-600"
        >
            <span class="text-base sm:text-lg md:text-xl uppercase">
            Play demo
            </span>
        </button>
        </div>
        <div v-else>
        <button class="w-full flex justify-center items-center py-2 sm:py-3 md:py-4 mt-2 bg-zinc-600 text-white cursor-default">
            <span class="text-base lg:text-xl uppercase">
            Demo unavailable
            </span>
        </button>
        </div>
      </div>
      <!-- <div
        v-if="!game.providerId"
        class="absolute top-0 left-0 bg-pink-600 px-6 py-2 text-lg rounded-br-xl uppercase text-white"
      >
        in development
      </div> -->
      <img
        :src="gameMedia?.bannerImage && gameMedia?.bannerImage?.file ? createFileURL(gameMedia.bannerImage?.file) : require('../../assets/no-image-available.jpg')"
        alt="game image"
        class="w-full object-cover h-[20rem] sm:h-[22rem]" :class="sidebarFixed ? 'lg:h-[26rem]' : 'lg:h-[32rem]'"
      />
      <div class="absolute top-0 left-0 w-full object-cover h-[20rem] sm:h-[22rem] pointer-events-none" 
        :class="sidebarFixed ? 'lg:h-[26rem]' : 'lg:h-[32rem]'"
        style="
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(9,9,11,1) 0%, rgba(34,6,56,0) 5%, rgba(35,7,56,0) 100%);" 
      ></div>
    </div>
    <div
      class="w-full pt-5 bg-zinc-950"
    > 
      <div class="w-full sm:flex justify-center pb-6 px-2 lg:px-4" style="">
        <!-- left container  -->
        <div class="h-full dark:text-white mr-6 lg:mr-8 w-full sm:w-1/2">
          <div class="pt-2 text-left fugaz">
            <h1 ref="originalHeader" class="dark:text-white font-normal uppercase text-2xl sm:text-4xl">
              {{ basicInfo.name }}
            </h1>
            <h1 class="dark:text-white font-normal uppercase text-xl sm:text-2xl">
              {{ basicInfo.sub_name }}
            </h1>
            <div class="w-36 h-1 sm:h-1.5 mb-2 mt-1.5 bg-pink-600"></div>
            <h1 class="font-normal uppercase text-xl sm:text-2xl text-pink-600">
              Game description
            </h1>
          </div>
          <div class="rounded-md sm:mb-6">
            <span
              v-html="gameDetails.descriptionTextEn"
              class="text-zinc-600 dark:text-zinc-300"
            ></span>
          </div>
        </div>
        <!-- right container  -->
        <div class="h-max md:w-[18rem] lg:w-[22rem] fugaz" style="">
          <div
            v-if="!userRoles.isPlatformGuest"
            class="w-full rounded-sm flex justify-between items-center gap-x-4 mb-4 pt-2 sm:pt-0"
          >
            <button
              v-if="gameMedia.docsFile?.url"
              @click="downloadDocs"
              class="px-5 py-1.5 lg:py-3 h-max w-full shrink bg-pink-600 text-white font-normal transition-colors duration-150 xl:hover:bg-purple-600 flex items-center justify-center"
            >
              <span class="pr-2 uppercase text-sm md:text-[16px] lg:text-[19px] whitespace-nowrap flex items-center gap-1">
                <span class="hidden lg:block">game</span> docs
              </span
              >
            </button>
            <button
              v-if="gameMedia.assetsFile?.url"
              @click="downloadAssets"
              class="px-3 py-1.5 lg:py-3 h-max w-full shrink bg-pink-600 text-white font-normal transition-colors duration-150 xl:hover:bg-purple-600 flex items-center justify-center"
            >
              <span class="uppercase text-sm md:text-[16px] lg:text-[19px] whitespace-nowrap flex items-center gap-1">
                <span class="hidden lg:block">promo</span> assets
              </span>
            </button>
          </div>
          <div
            class="bg-pink-600 dark:bg-zinc-900 rounded-sm flex flex-col sm:gap-1 py-3 px-4"
          >
            <div
              class="mb-1 sm:mb-0"
              v-if="
                gameDetails.supportedPlatforms?.desktop ||
                gameDetails.supportedPlatforms?.mobile
              "
            >
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Supported platforms
              </h4>
              <div class="flex gap-x-3 text-2xl sm:text-3xl text-white mt-1">
                <font-awesome-icon
                  v-if="gameDetails.supportedPlatforms.desktop"
                  :icon="['fas', 'laptop']"
                />
                <font-awesome-icon
                  v-if="gameDetails.supportedPlatforms.mobile"
                  :icon="['fas', 'mobile']"
                />
              </div>
            </div>
            <div v-if="gameDetails.format" class="">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Format
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">{{ gameDetails.format }}</h2>
            </div>
            <div v-if="gameDetails.rtp">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                RTP
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ gameDetails.rtp.toFixed(2) }} %
              </h2>
            </div>
            <div v-if="gameDetails.volatility">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                volatility
              </h4>
              <div
                class="flex gap-2 text-xl md:text-2xl lg:text-3xl text-white mt-1"
                :title="gameDetails.volatility"
              >
                <div v-for="index in fullCircle" :key="`circle${index}`">
                  <font-awesome-icon :icon="['fa', 'circle']" />
                </div>
                <div v-if="hasHalfCircle">
                  <font-awesome-icon :icon="['fas', 'circle-half-stroke']" />
                </div>
                <div
                  v-for="(n, index) in emptyCircles"
                  :key="`emptyCircle${index}`"
                >
                  <font-awesome-icon :icon="['far', 'circle']" />
                </div>
              </div>
            </div>
            <div v-if="gameDetails.hit_frequency">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Hit frequency
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ gameDetails.hit_frequency }} %
              </h2>
            </div>
            <div v-if="gameDetails.max_multiplier_win">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Max multiplier win
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                x{{ gameDetails.max_multiplier_win }}
              </h2>
            </div>
            <div v-if="gameDetails.max_bet">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Max bet
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ getCurrencyValue(gameDetails.max_bet) }}
              </h2>
            </div>
            <div v-if="gameDetails.min_bet">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Min bet
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ getCurrencyValue(gameDetails.min_bet) }}
              </h2>
            </div>
            <div>
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Pay Method
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal uppercase">
                {{ gameDetails.payMethod }}
              </h2>
            </div>
            <div class="flex items-center gap-4">
              <div v-if="gameDetails.lines && gameDetails.lines > 0">
                <h4
                  class="text text-pink-600 text-base lg:text-base font-normal uppercase"
                >
                  Lines
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                  {{ gameDetails.lines }}
                </h2>
              </div>
              <div v-if="gameDetails.reels">
                <h4
                  class="text text-pink-600 text-base lg:text-base font-normal uppercase"
                >
                  Reels
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                  {{ gameDetails.reels }}
                </h2>
              </div>
              <div v-if="gameDetails.gameRows">
                <h4
                  class="text text-pink-600 text-base lg:text-base font-normal uppercase"
                >
                  Rows
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                  {{ gameDetails.gameRows }}
                </h2>
              </div>
            </div>
            <div v-if="gameDetails.max_exposure">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Max exposure
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ getCurrencyValue(gameDetails.max_exposure) }}
              </h2>
            </div>
            <div v-if="gameDetails.isBuyBonus" class="">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Buy bonus
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal uppercase">
                {{ gameDetails.isBuyBonus ? 'Yes' : 'No' }}
              </h2>
            </div>
            <div
              v-for="(value, key, index) in gameDetails.propertiesData"
              :key="`propertiesData${index}`"
            >
              <div class="">
                <h4
                  class="text text-pink-600 text-base lg:text-base font-normal uppercase"
                >
                  {{ key }}
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-4xl text-white font-normal truncate">
                  {{ value }}
                </h2>
              </div>
            </div>
            <div v-if="gameDetails.release_date">
              <h4
                class="text text-pink-600 text-base lg:text-base font-normal uppercase"
              >
                Release date
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white fugaz uppercase">
                {{ getReleaseDate(gameDetails.release_date) }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <!-- gallery  -->
    </div>
    <div class="flex flex-col items-center mt-6 pt-4 w-full">
      <button 
        class="px-3 pt-1 pb-[3px] text-white xl:hover:text-pink-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md fugaz uppercase text-base" 
        @click="submitData"
      >
      Submit data
      </button>
    </div>
  </div>
</template>
  
  <script>
  import request from "@/services/axios";
  
  export default {
    name: "StepFour",
    data() {
      return {};
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      user() {
        return this.$store.getters["user/user"];
      },
      userRoles() {
        return this.user.localRoles;
      },
      basicInfo() {
        return this.$store.getters["newGame/basicInfo"];
      },
      gameDetails() {
        return this.$store.getters["newGame/gameDetails"];
      },
      imagesToUpload() {
        return this.$store.getters["newGame/imagesToUpload"];
      },
      gameMedia() {
        return this.$store.getters["newGame/gameMedia"];
      },
      fromStorage() {
        return this.$store.getters["newGame/fromStorage"];
      },
      sidebarFixed() {
        return this.$store.getters["sidebar/getSidebarFixed"];
      },
      fullCircle() {
        return Math.floor(this.gameDetails.volatility / 2);
      },
      hasHalfCircle() {
        return this.gameDetails.volatility % 2 !== 0;
      },
      emptyCircles() {
        const maxCircles = 5;
        return Math.floor(maxCircles - this.gameDetails.volatility / 2);
      },
    },
    methods: {
      downloadDocs() {
        window.open(this.gameMedia.docsFile.url);
      },
      downloadAssets() {
        window.open(this.gameMedia.assetsFile.url);
      },
      getReleaseDate(releaseDate) {
        if (releaseDate) {
          const date = new Date(releaseDate);
          const options = { year: "numeric", month: "long", day: "numeric" };
          return date.toLocaleDateString(this.appLanguage.long, options);
        } else return "N/A";
      },
      createFileURL(file) {
        var binaryData = [];
        binaryData.push(file);
        const fileURL = URL.createObjectURL(new Blob(binaryData));
        return fileURL;
      },
      async submitDocs(newGameId) {
        if(this.gameMedia.docsFile?.url) {
          await request.post(`/games/media/url/${newGameId}`, {
            url: this.gameMedia.docsFile?.url,
            mediaType: 'docs',
            type: 'url'
          })
        }
      },
      async submitAssets(newGameId) {
        if(this.gameMedia.assetsFile?.url) {
          await request.post(`/games/media/url/${newGameId}`, {
            url: this.gameMedia.assetsFile?.url,
            mediaType: 'assets',
            type: 'url'
          })
        }
      },
      async submitMedia(game) {
        // console.log(game.data);
        const newGameId = game.id;
        await this.submitDocs(newGameId)
        await this.submitAssets(newGameId)
        Promise.all(
          this.imagesToUpload.map((image) => {
            let formData = new FormData();
            formData.append("file", image.file);
            formData.append("isMainImage", image.isMainImage);
            formData.append("isBanner", image.isBanner);
            formData.append("isMiniBanner", image.isMiniBanner);
            formData.append(
              "mediaType",
              image.mediaType ? image.mediaType : "gallery"
            );
            formData.append("type", "file");
            return request.post(`/games/media/upload/${newGameId}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          })
        ).then(() => {
          this.$store.dispatch("newGame/setLoadingAction", false);
          this.$store.dispatch("newGame/resetFormAction");
          this.$router.push(`/game/${newGameId}`);
        });
      },
      async submitData() {
        try {
          this.$store.dispatch("newGame/setLoadingAction", true);
          let propertiesData = {};
          if(this.gameDetails.dataFields) {
            for (let i = 0; i < this.gameDetails.dataFields.length; i++) {
              propertiesData[this.gameDetails.dataFields[i].key] =
                this.gameDetails.dataFields[i].value;
            }
          }
          const url = this.fromStorage
            ? `/games/create/gamestorage`
            : `/games/create/local`;
          const gameInfo = {
            name: this.basicInfo.name,
            sub_name: this.basicInfo.sub_name,
            slug: this.basicInfo.slug,
            externalIdentifier: this.basicInfo.slug, // set this allow null on db, or remove this column ?

            rtp: this.gameDetails.rtp ? this.gameDetails.rtp.toFixed(2) : this.gameDetails.rtp,
            volatility: this.gameDetails.volatility,
            hit_frequency: this.gameDetails.hit_frequency,
            max_multiplier_win: this.gameDetails.max_multiplier_win > 0 ? 
              this.gameDetails.max_multiplier_win.toFixed(2) : 
              this.gameDetails.max_multiplier_win,
            type: this.gameDetails.type,
            propertiesData: propertiesData,
            platforms: [],
            description_en: this.gameDetails.descriptions ? this.gameDetails.descriptions.english : '',
            description_it: this.gameDetails.descriptions ? this.gameDetails.descriptions.italian : '',
            release_date: this.gameDetails.release_date,
            supportedPlatforms: this.gameDetails.supportedPlatforms ? this.gameDetails.supportedPlatforms : {desktop: false, mobile: false},
            format: this.gameDetails.format,
            max_bet: this.gameDetails.max_bet > 0 ? this.gameDetails.max_bet.toFixed(2) : this.gameDetails.max_bet,
            min_bet: this.gameDetails.min_bet > 0 ? this.gameDetails.min_bet.toFixed(2) : this.gameDetails.min_bet,
            lines: this.gameDetails.lines,
            pay_method: this.gameDetails.payMethod,
            reels: this.gameDetails.reels,
            gameRows: this.gameDetails.gameRows,
            isBuyBonus: this.gameDetails.isBuyBonus,
            isDev: this.gameDetails.isDev,
            isCatalog: true,
            // max_exposure_ratio: this.gameDetails.max_exposure_ratio,
          }
          if (this.basicInfo.idFromDB !== null) {
            gameInfo.id = this.basicInfo.idFromDB
          }
          const response = await request.post(
            url,
            gameInfo,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          await this.submitMedia(response.data);
        } catch (e) {
          console.log(e);
          this.$toast.error('Something wrong, please try again', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.$store.dispatch("newGame/setLoadingAction", false);
        }
      },
    },
  };
  </script>
  
  <style></style>
  
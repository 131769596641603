<template>
  <div class="w-full h-full min-h-full max-w-full bg-zinc-950 flex flex-col">
    <div class="w-full h-full rounded-md flex flex-col gap-4">
      <!-- header  -->
      <div class="flex justify-between px-4 md:px-7 pt-4 pb-4">
        <h2 class="text-white text-3xl xl:text-4xl pt-0.5 fugaz uppercase">
          {{ $t('charts.title') }}
        </h2>
        <div class="flex gap-2 items-center text-base lg:text-xl">
          <button @click="changeChartsType('Totals')" class="fugaz uppercase" 
            :class="chartsType === 'Totals' ? 'text-pink-600' : 'text-white'"
          >
            {{ $t('charts.totals.title') }}
          </button>
          <button @click="changeChartsType('Specific')" class="fugaz uppercase"
            :class="chartsType === 'Specific' ? 'text-pink-600' : 'text-white'"
          >
            {{ $t('charts.specific.title') }}
          </button>
        </div>
      </div>
      <div v-if="ready" class="flex flex-col h-full">
        <ChartsTotals 
          v-if="chartsType === 'Totals'" 
          :chartKey="chartKey"
          :getTodayStart="getTodayStart" 
          :getTodayEnd="getTodayEnd" 
          :allGames="allGames"
          :allPlatforms="allPlatforms"
          :userPlatform="userPlatform"
        />
        <ChartsSpecific 
          v-if="chartsType === 'Specific'" 
          :chartKey="chartKey"
          :getTodayStart="getTodayStart" 
          :getTodayEnd="getTodayEnd" 
          :allGames="allGames"
          :allPlatforms="allPlatforms"
          :userPlatform="userPlatform"
        />
      </div>
      <div v-else class="flex items-center justify-center w-full h-[90vh] bg-zinc-950">
        <img src="../../assets/gai-logo-full.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
      </div>
    </div>
  </div>
</template>

<script>
  import request from "@/services/axios";
  import ChartsTotals from "@/components/charts/ChartsTotals.vue";
  import ChartsSpecific from "@/components/charts/ChartsSpecific.vue";

  export default {
    data() {
      return {
        ready: false,
        allGames: [],
        allPlatforms: [],
        userPlatform: null,
        chartsType: 'Totals',
        serverError: false, 
        resizeTimeout: null,
        chartKey: 0,
        mountTimer: null,
      }
    },
    components: {
      ChartsTotals,
      ChartsSpecific,
    },
    async mounted() {
      this.mountTimer = setTimeout(async () => {
        await this.getAllGames()
        await this.getAllPlatforms()
        this.ready = true
        window.addEventListener('resize', this.handleResize);
      }, 500);
    },
    beforeUnmount() {
      clearTimeout(this.mountTimer);
      // Remove event listener when component is destroyed
      window.removeEventListener('resize', this.handleResize);
    },
    watch: {
      
    },
    computed: {
      user() {
        return this.$store.getters["user/user"]
      },
    },
    methods: {
      handleResize() {
        // Clear previous timeout to debounce
        clearTimeout(this.resizeTimeout);

        // Debounce by setting a new timeout
        this.resizeTimeout = setTimeout(() => {
          // Call your function here
          this.chartKey++
        }, 300); // Adjust the delay as needed
      },
      changeChartsType(type) {
        // console.log(`changingChartsTo${type}`)
        this.emitter.emit(`changingChartsTo${type}`);
        this.chartsType = type
      },
      getTodayStart() {
        const today = new Date();
        today.setHours(0, 0, 0, 0)
        return today
      },
      getTodayEnd() {
        const today = new Date();
        today.setHours(23, 59, 59, 999)
        return today
      },
      async getAllPlatforms() {
        try {
          if (Object.keys(this.user.attributes).length > 0) {
            const platform = (await request.get(`/platforms/byName/${this.user.attributes.platform[0]}`)).data
            this.userPlatform = platform
          } else {
            const platforms = (await request.get('/platforms?with=games')).data.rows
            this.allPlatforms = platforms
          }
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
      async getAllGames() {
        try {
          const games = (await request.get("/catalog")).data?.rows.map(game => ({
            id: game.id,
            name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name,
            isDev: game.isDev,
            slug: game.slug
          })).filter(game => !game.isDev)
          
          this.allGames = games
        } catch (e) {
          console.log(e);
          this.serverError = true;
        }
      },
    }
  }
  
</script>

<style scoped>
.vc-popover-content-wrapper select {
  background-color: black;
}
</style>async 
<template>
  <div @click="openOrCloseCharts('platform', false)" 
    class="w-full h-full flex justify-center items-center bg-black bg-opacity-75 fixed top-0 left-0 z-[9999]">
    <div @click.stop class="bg-zinc-800 flex flex-col gap-2.5 rounded-md relative px-5 pt-5 pb-12" style="height: 80%; width: 75%;">
      <button @click.stop="openOrCloseCharts('platform', false)" class="absolute top-2.5 right-3">
        <font-awesome-icon :icon="['fas', 'xmark']" class="h-6 text-zinc-300" />
      </button>
      <div class="flex items-end gap-2">
        <h1 class="text-white fugaz uppercase text-2xl">
          Platform charts
        </h1>
        <h4 class="text-zinc-300 text-xs mb-1 fugaz uppercase">
          From <span class="text-white text-sm">{{ clearDate(dateRangeFrom) }}</span>
        </h4>
        <h4 class="text-zinc-200 text-xs mb-1 fugaz uppercase">
          To <span class="text-white text-sm">{{ clearDate(dateRangeTo) }}</span>
        </h4>
      </div>
      <div v-if="!loading" class="w-full h-full flex justify-center">
        <Bar
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"
        />
      </div>
      <div v-else class="flex items-center justify-center h-full w-full">
        <img v-if="!serverError" src="../../assets/loading2.png" alt="loading icon"
          class="text-zinc-400 h-8 animate-spin"
        />
        <div v-if="serverError">
          Error trying to connect to the server.
          <button @click="$router.go()" class="text-blue-600">Reload</button> page
          to try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import request from "@/services/axios";
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)  

  export default {
    data() {
      return {
        loading: false,
        serverError: false,
        games : [],
        chartOptions: {
          responsive: true,
          plugins: {
            legend: {
              labels: {
                color: 'white' // Change label text color here
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: 'white', // Change scale label (number) color here
                font: {
                  family: 'Fugaz One, cursive', // Change font-family here
                  weight: 'medium',
                  size: 12, // Adjust font size as needed
                }
              },
              grid: {
                color: 'rgb(82 82 91)',
                borderColor: 'green'
              }
            },
            x: {
              ticks: {
                color: 'white', // Change scale label (number) color here
                font: {
                  family: 'Fugaz One, cursive', // Change font-family here
                  weight: 'medium',
                  size: 12, // Adjust font size as needed
                }
              },
              grid: {
                color: 'rgb(82 82 91)',
                borderColor: 'green'
              }
            }
          }
        }
      }
    },
    components: { Bar },
    props: {
      openOrCloseCharts: Function,
      platform: Object,
      dateRangeFrom: Date,
      dateRangeTo: Date,
      getGameGrossWin: Function,
      getGameSessionsCount: Function,
      getCurrencyValue: Function,
    },
    created() {
      this.getAllSessions()
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      chartData() {
        return {
          labels: this.games.map(game => game.name),

          datasets: [
            {
              label: 'Gross win',
              backgroundColor: 'rgb(219 39 119)',
              data: this.games.map(game => game.grossWin)
            },
            {
              label: 'Sessions',
              backgroundColor: 'rgb(228 228 231)',
              data: this.games.map(game => game.sessionsCount)
            }
          ]
        }
      }
    },
    methods: {
      clearDate(date) {
        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return new Date(date).toLocaleDateString(this.appLanguage.long, options);
      },
      async getAllSessions() {
        this.loading = true;
        try {
          // console.log('this.dateRangeFrom = ' + this.dateRangeFrom);
          // console.log('this.dateRangeTo = ' + this.dateRangeTo);
          const sqlFrom = new Date(this.dateRangeFrom).toISOString().slice(0, 19);
          const sqlTo = new Date(this.dateRangeTo).toISOString().slice(0, 19);
          // console.log('sqlFrom: ' + sqlFrom + ' sqlTo: ' + sqlTo);
          const sessions = (
            await request.get(
              `/bookkeeping/sessions/range?from=${sqlFrom}&to=${sqlTo}&platformId=${this.platform.id}`
            )
          ).data.rows;
          const extractedSessions = sessions.map(session => ({
            platform: session.platform,
            game: session.game,
            isOpen: session.isOpen,
            bet: session.bet,
            win: session.win,
            id: session.id,
            name: session.name
          }));

          const uniqueGames = []
          for (const session of extractedSessions) {
            if(!uniqueGames.map(game => game.id).includes(session.game.id)) {
              const game = session.game
              game.grossWin = this.getGameGrossWin(game, extractedSessions)
              game.sessionsCount = this.getGameSessionsCount(game, extractedSessions)
              uniqueGames.push(session.game);
            }
          }
          this.games = uniqueGames
          this.games.sort((a, b) => b.grossWin - a.grossWin)

          // console.log(extractedSessions);

        } catch (e) {
          console.log(e);
          // TODO review this DRAMATIC error handling approach
          this.serverError = true;
        }
        this.loading = false;
      },
    }

  }
</script>

<style scoped>
.chartjs-render-monitor .chart-legend li span {
  color: white !important;
}
.chartjs-render-monitor .chartjs-chart {
  text-transform: uppercase; /* Change text to uppercase */
}
</style>
<template>
  <div 
    class="hidden xl:flex flex-col items-center group h-screen fixed transition-all duration-75 border-zinc-900 border-r bg-zinc-920 text-white shadow-md" 
    :class="[
      (sidebarFixed ? 'w-72' : 'w-32'), 
      (isHovered && 'w-72')
    ]" 
    style="z-index: 9;" 
    @click="userBox = false"
    @mouseenter="hoverActions(true)"
    @mouseleave="hoverActions(false)"
  >
  <div class="flex flex-col h-full max-h-[92%] w-full overflow-auto" :class="userBox ? 'opacity-30 pointer-events-none' : 'opacity-100'">
    <!-- Header -->
    <div @click="$router.push({ name: 'Catalog'})" 
      class="w-full flex items-center justify-center border-zinc-900 cursor-pointer bg-zinc-920 h-28 pt-3"
    > 
      <img src="../assets/gai-logo-full.png" alt="gai gub logo" 
        class="w-24 h-24 transition-all duration-300 object-top"
      >
      <!-- <img src="../assets/gai-logo-full.png" alt="gai gub logo" 
        class="w-auto h-full object-contain transition-all duration-300 object-top" 
        :class="sidebarFixed ? 'block' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'"
      > -->
    </div>
    <router-link 
      v-if="!userRoles.isPlatformGuest && !userRoles.isPlatformManager"
      :to="{ name: 'Dashboard'}"
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="[
        ($route.name === 'Dashboard' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900')
      ]"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon icon="fa-solid fa-chart-line" class="h-5" :class="$route.name === 'Dashboard' ? 'ml-[5px]' : 'ml-2'"  />
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'"
        >
        {{ $t('sidebar.dashboard') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      v-if="!userRoles.isPlatformManager"
      :to="{ name: 'Catalog'}"
      class="flex items-center py-4 xl:hover:brightness-110 w-full group/dashboard"
      :class="($route.name === 'Catalog' || $route.name === 'Game') ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
      >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <img class="h-9 w-auto object-contain -mb-2.5 invert"
          :class="($route.name === 'Catalog' || $route.name === 'Game') ? '-ml-0.5' : 'ml-[1px]'" 
          src="../assets/noun-slot-machine-4476814.svg" alt="slots">
        <h2 class="ml-[2px] text-lg fugaz uppercase -mb-1"
        :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.catalog') }}
        </h2>
      </div>
    </router-link>
    <router-link
      :to="{ name: 'Games', params: { stepNumber: '1' }}"
      v-if="userRoles.isLudusAdmin" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="($route.name === 'Games') ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
      >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <img 
          class="h-6 w-auto object-contain invert" 
          :class="$route.name === 'Games' ? 'ml-1' : 'ml-[7px]'" 
          src="../assets/slots.png" alt="slots">
        <h2 class="ml-[7px] text-lg fugaz uppercase -mb-1 whitespace-nowrap"
        :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.games') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      v-if="!userRoles.isPlatformGuest"
      :to="{ name: 'Sessions'}"
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="[
        (($route.name === 'Sessions' || $route.name === 'Sessions List') ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'),
        
      ]"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'chalkboard-user']" class="h-5" 
          :class="($route.name === 'Sessions' || $route.name === 'Sessions List')  ? 'ml-1' : 'ml-[7px]'"  />
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'"
        >
          {{ $t('sidebar.sessions') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      v-if="!userRoles.isPlatformGuest && !userRoles.isPlatformManager"
      :to="{ name: 'Charts'}"
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="[
        ($route.name === 'Charts' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'),
      ]"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'chart-pie']" class="h-5" :class="$route.name === 'Charts' ? 'ml-1' : 'ml-[7px]'"  />
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.charts') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      :to="{ name: 'Platforms'}"
      v-if="userRoles.isLudusAdmin || userRoles.isLudusManager" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="($route.name === 'Platforms' || $route.name === 'New platform' || $route.name === 'Platform') ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <img 
          class="h-6 w-auto object-contain -mb-1 invert" 
          :class="($route.name === 'Platforms' || $route.name === 'New platform' || $route.name === 'Platform') ? 'ml-1' : 'ml-[7px]'" 
          src="../assets/data-transfer.svg" alt="platforms image">
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.platforms') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      :to="{ name: 'Bets'}"
      v-if="userRoles.isLudusAdmin"
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="[
        ($route.name === 'Bets' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'),
      ]"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <img src="../assets/coin.png" alt="casino chips icon" class="h-6 w-auto object-contain -mb-1 invert" :class="$route.name === 'Bets' ? 'ml-1' : 'ml-[7px]'" >
        <!-- <font-awesome-icon :icon="['fas', 'coins']" class="h-5" :class="$route.name === 'Bets' ? 'ml-1' : 'ml-[7px]'"  /> -->
        <h2 class="ml-1.5 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.bets') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      :to="{ name: 'Maintenances'}"
      v-if="userRoles.isLudusAdmin" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="$route.name === 'Maintenances' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'gear']" class="h-6" :class="$route.name === 'Maintenances' ? 'ml-1' : 'ml-[7px]'" />
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.maintenances') }}
        </h2>
      </div>
    </router-link>
    <router-link 
      v-if="userRoles.isLudusAdmin" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="$route.name === 'Logs' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
      :to="{ name: 'Logs'}"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'bars']" class="h-6 pl-[2px]" :class="$route.name === 'Logs' ? 'ml-1' : 'ml-[7px]'"/>
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.logs') }}
        </h2>
      </div>
    </router-link>
    <router-link  
      v-if="userRoles.isLudusAdmin || userRoles.isPlatformAdmin" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="$route.name === 'Users' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
      :to="{ name: 'Users'}"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'users']" class="h-5 pl-[2px]" :class="$route.name === 'Users' ? 'ml-1' : 'ml-[7px]'"/>
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.users') }}
        </h2>
      </div>
    </router-link>
    <router-link  
      v-if="userRoles.isLudusAdmin" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="$route.name === 'MailingList' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
      :to="{ name: 'MailingList'}"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'envelope']" class="h-5 pl-[2px]" :class="$route.name === 'MailingList' ? 'ml-1' : 'ml-[7px]'"/>
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.mailing') }}
        </h2>
      </div>
    </router-link>
    <router-link  
      v-if="userRoles.isLudusAdmin || userRoles.isPlatformAdmin" 
      class="flex items-center py-4 xl:hover:brightness-110 w-full"
      :class="$route.name === 'Tickets' ? 'border-l-4 border-white bg-pink-600' : 'opacity-50 xl:hover:opacity-100 xl:hover:bg-zinc-900'"
      :to="{ name: 'Tickets'}"
    >
      <div class="flex items-center w-36 h-[28px]" :class="sidebarFixed ? 'ml-[25%]' : 'ml-[35%] group-hover:ml-[25%]'">
        <font-awesome-icon :icon="['fas', 'ticket']" class="h-5 pl-[2px]" :class="$route.name === 'Tickets' ? 'ml-1' : 'ml-[7px]'"/>
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1 whitespace-nowrap flex items-center gap-1.5"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">
          {{ $t('sidebar.tickets') }}
          <span v-if="openTickets.length > 0" class="text-sm text-white">({{ openTickets.length }})</span>
        </h2>
      </div>
    </router-link>
  </div>
  
    <div
      class="fixed bottom-0 flex flex-col justify-center items-center bg-zinc-950 z-[3] group/user"
      :class="[
        (sidebarFixed ? 'w-[279px]' : 'w-32'), 
        (isHovered && 'w-[279px]')
      ]" 
    >
      <div class="flex flex-col gap-1.5 items-center w-full z-[1] transition-all duration-200"
        v-if="userBox"
      >
        <router-link :to="{ name: 'Notifications'}" 
          class="h-[36px] pt-1.5 pb-1 pl-[30%] bg-zinc-900 transition-all duration-150 xl:hover:brightness-125 w-full fugaz uppercase text-white flex items-center gap-1.5 group/notif truncate">
          <font-awesome-icon :icon="['fas', 'bell']" class="w-[24px] group-hover/notif:text-pink-600 text-white pb-0.5"/>
          {{ $t('sidebar.notifications') }}
          <span v-if="unseenNotifications.length" class="text-xs text-red-500 font-bold">
            {{ unseenNotifications.length }}
          </span>
        </router-link>
        <router-link :to="{name: 'Settings'}" 
          class="h-[36px] pt-1.5 pb-1 pl-[30%] bg-zinc-900 transition-all duration-150 xl:hover:brightness-125 w-full fugaz uppercase flex items-center gap-1.5 group/settings truncate"
        >
          <font-awesome-icon :icon="['fas', 'user-gear']" class="w-[24px] xl:group-hover/settings:text-pink-600 text-white pb-0.5"/>
          {{ $t('sidebar.settings') }}
        </router-link>
        <button @click="logout()" 
          class="h-[36px] pt-1.5 pb-1 pl-[30%] bg-zinc-900 transition-all duration-150 xl:hover:brightness-125 w-full fugaz uppercase text-white flex items-center gap-1.5 group/logout truncate">
          <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="w-[24px] xl:group-hover/logout:text-pink-600 text-white pb-0.5"/>
          {{ $t('sidebar.logout') }}
        </button>
      </div>
      <button
        @click.stop="userBox = !userBox"
        class="py-2.5 border-zinc-800 bg-zinc-950 xl:hover:brightness-95 mt-1 w-full flex justify-center z-[2] group/userBox"
        :class="[
            (sidebarFixed ? 'flex justify-center' : 'group-hover:flex group-hover:justify-center')
          ]">
        <font-awesome-icon v-if="unseenNotifications.length" :icon="['fas', 'circle']" class="h-2 w-2 mr-1 text-red-500"/>
        <font-awesome-icon icon="fa-solid fa-circle-user" class="text-2xl" 
          :class="userBox ? 'text-pink-500' : 'group-hover/userBox:text-pink-500'"/>
        <h2 class="ml-2 text-lg fugaz uppercase -mb-1"
          :class="sidebarFixed ? 'block opacity-100' : 'hidden opacity-0 group-hover:block group-hover:opacity-100'">{{ user.username }}</h2>
      </button>
    </div>
    <button @click="fixSidebar"
      @mouseenter="buttonHovered = true"
      @mouseleave="buttonHovered = false"
      class="fixed top-4 left-[258px] justify-center items-center rounded-l-lg bg-pink-600 h-14 w-7 transition-all duration-300" 
      :class="[
        (isHovered ? 'opacity-100' : ' opacity-0 pointer-events-none'),
        (screenWidth > 1366 ? 'flex' : 'hidden'),
      ]"
      :title="sidebarFixed ? 'Unfix Sidebar' : 'Fix Sidebar'"
      >
      <font-awesome-icon v-if="sidebarFixed" :icon="['fas', 'arrow-left']" />
      <font-awesome-icon v-else :icon="['fas', 'arrow-right']" />
    </button>
  </div>
</template>

<script>
import request from '@/services/axios'
import {keycloak} from '../keycloak.config'

export default {
  name: 'SideBar',
  data() {
    return {
      userBox: false,
      isHovered: false,
      buttonHovered: false,
      fetchingProgress: {},
      progressSize: 0,
      progressStep: '',
      loadingPercentage: 0,
      openLastJob: false,
      jobError: false,
      fetchingTotals: false,
      openTickets: [],
    }
  },
  watch: {
    isHovered() {
      if(!this.isHovered && !this.sidebarFixed) {
        this.userBox = false
      }
    }
  },
  computed: {
    screenHeight() {
      return this.$store.getters['getScreenHeight']
    },
    screenWidth() {
      return this.$store.getters['getScreenWidth']
    },
    progressBarClass() {
      return `width: ${this.loadingPercentage}%;`
    },
    fetchingSessions() {
      return this.$store.getters['jobs/fetching']
    },
    lastJob() {
      return this.$store.getters['jobs/lastJob']
    },
    isDarkMode() {
      return this.$store.getters['getDarkMode']
    },
    sidebarFixed() {
      return this.$store.getters['sidebar/getSidebarFixed']
    },
    user() {
      return this.$store.getters['user/user']
    },
    userRoles() {
      return this.user.localRoles
    },
    unseenNotifications() {
      const userNotifications = this.$store.getters['user/notifications']
      return userNotifications.filter(notif => !notif.seen)
    },
  },
  async created() {
    this.emitter.on('clickOut', this.closeUserBox)
    this.emitter.on('closeTicket', this.getOpenTickets)
    this.emitter.on('ticketUpdated', this.getOpenTickets)
    await this.getOpenTickets()
  },
  methods: {
    closeUserBox() {
      this.userBox = false
    },
    openFetchingDetails() {
      // if (this.lastJob.completed) {
        this.openLastJob = !this.openLastJob
      // }
    },
    goToSessions() {
      this.$router.push({ name: 'Sessions'}); 
      this.openLastJob = false
      this.$store.dispatch('jobs/setLastJobAction', {})
      this.$store.dispatch('jobs/setFetchingAction', false)
      this.loadingPercentage = 0;
    },
    closeFetchingBox() {
      this.openLastJob = false
      this.$store.dispatch('jobs/setLastJobAction', {})
      this.$store.dispatch('jobs/setFetchingAction', false)
    },
    resetJobProgress(data) {
      // console.log(data)
      const job = {
        ...this.lastJob,
        ...data,
        completed: true,
      }
      this.$store.dispatch('jobs/setLastJobAction', job)
      this.$store.dispatch('jobs/setFetchingAction', false)
      this.fetchingProgress = "";
      this.fetchingTotals = false
    },
    hoverActions(action){
      // if(!this.buttonHovered){
        this.isHovered = action
      // } 
    },
    setDarkMode() {
      this.$store.dispatch('setDarkModeAction', !this.isDarkMode)
    },
    fixSidebar() {
      if(this.sidebarFixed){
        this.$nextTick(() => {
          this.isHovered = false
          this.$store.dispatch('sidebar/setSidebarFixedAction', false)
        })
      }
      else {
        this.$nextTick(() => {
          // this.isHovered = true
          this.$store.dispatch('sidebar/setSidebarFixedAction', true)
        })
      }
    },
    logout() {
      keycloak.logout()
    },
    async getOpenTickets() {
      try {
        const openTickets = (await request.get('/tickets?status=open')).data
        this.openTickets = openTickets
      } catch (e) {
        console.log(e)
        this.$toast.error('Something went wrong getting open tickets')
      }
    }
  }
}
</script>

<style>

</style>
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import catalog from './modules/catalog'
import requesting from './modules/requesting'
import sidebar from './modules/sidebar'
import user from './modules/user'
import game from './modules/game'
import newGame from './modules/newGame'
import sessions from './modules/sessions'
import jobs from './modules/jobs'
import dashboard from './modules/dashboard'
import charts from './modules/charts'

export default createStore({
  modules: {
    catalog, sidebar, user, game, requesting, newGame, sessions, jobs, dashboard, charts
  },
  state: {
    isDarkMode: true,
    mobileSidebar: false,
    screen: {
      width: 0,
      height: 0
    },
    language: {
      short: 'en',
      long: 'en-GB'
    },
  },
  getters: {
    getDarkMode: state => state.isDarkMode,
    getMobileSidebar: state => state.mobileSidebar,
    getScreenWidth: state => state.screen.width,
    getScreenHeight: state => state.screen.height,
    getLanguage: state => state.language,
  },
  actions: {
    setDarkModeAction({ commit }, payload) {
      commit('setDarkMode', payload)
    },
    setMobileSidebarAction({commit}, payload) {
      commit('setMobileSidebar', payload)
    },
    setScreenWidthAction({commit}, payload) {
      commit('setScreenWidth', payload)
    },
    setScreenHeightAction({commit}, payload) {
      commit('setScreenHeight', payload)
    },
    setLanguageAction({commit}, payload) {
      commit('setLanguage', payload)
    },
  },
  mutations: {
    setDarkMode(state, payload) {
      state.isDarkMode = payload
    },
    setMobileSidebar(state, payload) {
      state.mobileSidebar = payload
    },
    setScreenWidth(state, payload) {
      state.screen.width = payload
    },
    setScreenHeight(state, payload) {
      state.screen.height = payload
    },
    setLanguage(state, payload) {
      state.language = payload
    },
  },
  plugins: [createPersistedState({
    paths: ['sidebar', 'isDarkMode', 'catalog', 'newGame', 'language', 'jobs']
  })]
})
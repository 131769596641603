<template>
  <div v-show="jobRunning" @click.stop 
    class="fixed h-max w-max bottom-16 xl:bottom-4 right-[26px] bg-zinc-700 rounded-md z-[999] text-white"
    :class="open ? 'opacity-100' : 'opacity-50 hover:opacity-100'"
  >
    <button v-if="!open" @click="toggleOpenOnChildClick" class="p-2 flex items-center justify-center"
      :title="activeJob.state === 'ended' ? 'All jobs are finished' : 'A job is running in background'"
    >
      <img v-if="activeJob.state !== 'ended'" src="../assets/loading2.png" alt="loading icon"
        class="text-zinc-400 h-[30px] animate-spin pointer-events-none"
      />
      <font-awesome-icon v-else :icon="['fas', 'check']" class="text-green-500 h-6" />
    </button>
    <div v-else class="relative">
      <button @click.stop="close" class="absolute top-0.5 right-1.5 text-white hover:text-pink-600 z-[5]">
        <font-awesome-icon :icon="['fas', 'xmark']" class="h-[18px]" />
      </button>
      <div class="w-full px-2.5 pt-1.5 pb-0.5 z-[5]">
        <h5>{{ activeJob.state === 'ended' ? 'Last job' : 'Active job' }}:</h5>
        <div v-if="jobOnQueue">
          One job is waiting on queue
        </div>
        <div v-else class="flex items-center gap-2">
          <h4 class="fugaz uppercase">
            {{ activeJob.name }} <span v-if="activeJob.percentage">- {{ Math.floor(activeJob.percentage) }}%</span>
          </h4>
          <img v-if="activeJob.state !== 'ended'" src="../assets/loading2.png" alt="loading icon"
            class="text-zinc-400 h-[25px] animate-spin pointer-events-none mb-[2px]"
          />
        </div>
      </div>
      <div v-if="activeJob.message" class="bg-zinc-800 relative rounded-b-md">
        <div 
          class="absolute left-0 h-full z-[1] transition-all duration-300" 
          :class="[(jobError  ? 'bg-red-500 w-full' : 'bg-green-600'), (activeJob.percentage > 95 ? 'rounded-b-md' : 'rounded-bl-md')]"
          :style="!jobError && progressBarClass" 
          >
        </div>
        <div class="z-[10] pt-1 relative font-medium flex flex-col">
          <div class="px-2 flex flex-col items-center">
            <span>Progress:</span>
            <span class="font-thin fugaz uppercase text-lg">{{ activeJob.message }}</span>
          </div>
          <div v-if="activeJob.data !== null" class="w-full flex flex-col mt-1">
            <div v-for="(value, key) in activeJob.data" 
              class="capitalize w-full flex items-center justify-between border-t border-zinc-700 py-1.5 px-2 bg-zinc-800 xl:hover:bg-zinc-900 cursor-default">
              <span>
                {{ insertSpaceBeforeUppercase(key) }} 
              </span>
              <span>
                {{ value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/services/axios';


  export default {
    data() {
      return {
        open: false,
        jobError: false,
      }
    },
    mounted() {
      this.socket.on("job-progress", (response) => {
        this.jobError = false
        // console.log(response)
        const message = response.message
        // console.log(message)
        if(response.data.userId == this.user.userId) {
          if (message.name) {
            this.updateActiveJob({
              ...this.activeJob,
              name: message.name,
              message: message.progress.message,
              percentage: message.progress.percentage,
              state: 'running'
            })
          }
          if(message.data) {
            this.updateActiveJob({
              ...this.activeJob,
              data: message.data
            })
          }
          if (message.type === 'end') {
            this.updateActiveJob({
              ...this.activeJob,
              state: 'ended'
            })
          } 
          if (message.type === 'start') {
            this.updateActiveJob({
              ...this.activeJob,
              message: '',
              data: null,
              state: 'running'
            })
          }
        }
      });
      this.socket.on("disconnect", (response) => {
        if (this.activeJob.state !== null) {
          this.updateActiveJob({
            ...this.activeJob,
            name: 'Lost connection to jobs server',
            message: 'Trying to reconnect',
            state: 'disconnected'
          })
          this.jobError = true
        } 
      })
      this.socket.on("job-error", (response) => {
        if(response.data.userId ==  this.user.userId) {
          this.updateActiveJob({
            ...this.activeJob,
            name: 'Something went wrong',
            message: 'Please try again',
            state: 'error'
          })
          this.jobError = true
        }
      })
      this.emitter.on('clickOut', this.toggleOpen)
      if(this.activeJob.state == 'error' || (!this.jobError && this.activeJob.state == 'disconnected')) {
        this.clearJobs()
      }
      this.getActiveJobs()
    },
    // beforeUnmount() {
    //   this.socket.off("job-progress");
    //   this.socket.off("disconnect");
    //   this.socket.off("job-error");
    // },
    watch: {
      // open() {
      //   if(this.activeJob.state == 'error' || (!this.jobError && this.activeJob.state == 'disconnected')) {
      //     this.clearJobs()
      //   }
      // },
    },
    computed: {
      user() {
        return this.$store.getters["user/user"]
      },
      activeJob() {
        return this.$store.getters['jobs/activeJob']
      },
      jobRunning() {
        return this.activeJob.state !== null
      },
      jobOnQueue() {
        return this.activeJob.state === 'called' && !this.activeJob.message
      },
      progressBarClass() {
        return `width: ${this.activeJob.percentage}%;`
      },
    },
    methods: {
      async getActiveJobs() {
        try {
          const activeJobs = (await request.get('jobs/active/bookkeeping')).data
          if (activeJobs.length == 0) {
            this.clearJobs()
          } 
        } catch (e) {
          console.error(e.message)
        }
      },
      updateActiveJob(body) {
        this.$store.dispatch('jobs/setActiveJobAction', body)
      },
      insertSpaceBeforeUppercase(str) {
        return str.replace(/([A-Z])/g, ' $1').trim();
      },
      toggleOpenOnChildClick() {
        if (!this.isDragging) {
          // Only execute toggleOpen when dragging is not active
          this.toggleOpen(true);
        }
      },
      toggleOpen(value = false) {
        this.open = value
      },
      close() {
        if (this.activeJob.state === 'ended') {
          this.clearJobs()
        } else {
          this.open = false
        }
      },
      clearJobs() {
        this.$store.dispatch('jobs/setActiveJobAction', {
          name: null,
          message: null,
          data: null,
          jobId: null,
          userId: null,
          state: null
        })
      },
    }
  }

</script>
<style>
</style>
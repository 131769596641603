<template>
  <div class="text-white w-full flex flex-col items-center justify-start gap-4 pb-[65px] xl:pb-0 py-4 px-4 md:px-7">
    <div class="w-full flex justify-between">
      <h1 class="text-3xl xl:text-4xl text-center fugaz uppercase">
        {{ $t('tickets.newTicket') }}
      </h1>
    </div>
    <div class="w-full h-max flex flex-col pb-4 border border-zinc-800 rounded-md">
      <div v-if="ready" class="w-full px-3 flex items-center gap-2">
        <div class="py-2 w-full flex flex-col gap-1">
          <span class="text-sm font-light">{{ $t('tickets.title') }}</span>
          <input type="text" v-model="ticketTitle" class="py-1.5 w-full bg-zinc-900 pl-2 outline-none rounded-md">
        </div>
        <div class="py-2 flex flex-col gap-1">
          <span class="text-sm font-light">{{ $t('tickets.sessionId') }}</span>
          <span v-if="propSessionId !== '' && propSessionId !== undefined" 
            class="py-1.5 w-44 pl-2 rounded-md opacity-30 cursor-default bg-zinc-800"
          > 
            {{ sessionId }}
          </span>
          <input v-else type="text" v-model="sessionId" 
            class="py-1.5 w-44 bg-zinc-900 pl-2 outline-none rounded-md disabled:opacity-60">
        </div>
        <div v-if="loggedUser.localRoles.isLudusAdmin" class="py-2 flex flex-col gap-1">
          <span class="text-sm font-light">{{ $t('tickets.platform') }}</span>
          <div class="w-[250px] capitalize">
            <Multiselect
              mode="single"
              v-model="selectedPlatform"
              :options="allPlatforms"
              :close-on-select="true"
              :searchable="true"
              label="name"
              value-prop="id"
              :placeholder="$t('bets.selectPlatform')"
              ref="multiselectGame"
              :disabled="propSessionId !== '' && propSessionId !== undefined" 
            />
          </div>
        </div>
        <div class="py-2 flex flex-col gap-1">
          <span class="text-sm font-light">{{ $t('tickets.game') }}</span>
          <div class="w-[200px] capitalize">
            <Multiselect
              mode="single"
              v-model="selectedGame"
              :options="currentGames"
              :close-on-select="true"
              :searchable="true"
              label="name"
              value-prop="id"
              :placeholder="$t('bets.selectGame')"
              ref="multiselectGame"
              :disabled="propSessionId !== '' && propSessionId !== undefined" 
            />
          </div>
        </div>
      </div>
      <div class="px-3 pb-2 flex flex-col gap-1">
        <span class="text-sm font-light">{{ $t('tickets.bugDescription') }}</span>
        <RichTextInput :value="ticketDescription" @input="updateDescription" class="text-black" />
      </div>
      <div v-if="mediasToUpload.length > 0" class="flex flex-col gap-1 items-center mt-1">
        <span class="font-light">{{ $t('tickets.attachments') }}:</span>
        <div class="flex flex-wrap items-center justify-center gap-1.5">
          <button v-for="(media, index) in mediasToUpload" @click="removeMedia(index)" 
            class="font-semibold hover:text-red-500 px-1.5 bg-zinc-700 rounded-md">
            {{ media.name }}
          </button>
        </div>
      </div>
      <div class="px-3 flex flex-wrap items-start justify-start gap-2 mt-2.5">
        <label for="attachments" 
          class="px-2.5 py-0.5 bg-zinc-700 hover:bg-zinc-800 rounded-md font-semibold flex items-center gap-1.5 cursor-pointer hover:text-pink-600">
          <font-awesome-icon :icon="['fas', 'paperclip']" class="h-3.5 mb-0.5" />
          {{ $t('tickets.addAttachments') }}
          <input
            class="hidden"
            type="file"
            id="attachments"
            ref="attachments"
            multiple
            @click="$event.target.value = null"
            @change="handleFileUpload()"
          />
          <font-awesome-icon :icon="['fas', 'plus']" class="h-3.5" />
        </label>
      </div>
      <div @click="createTicket" class="flex items-center justify-center gap-2 mt-1">
        <button class="pt-1 pb-0.5 px-3 rounded-md bg-green-600 hover:bg-green-700 fugaz uppercase">{{ $t('tickets.sendTicket') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import request from "@/services/axios"
import RichTextInput from "../components/games/RichTextInput"

export default {
  data() {
    return {
      ready: false,
      ticketTitle: '',
      ticketDescription: '',
      session: null,
      sessionId: '',
      selectedGame: null,
      selectedPlatform: null,
      allGames: [],
      currentGames: [],
      allPlatforms: [],
      mediasToUpload: [],
    }
  },
  props: {
    propSessionId: String,
  },
  components: {
    RichTextInput,
    Multiselect,
  },
  watch: {
    propSessionId() {
      if(this.propSessionId !== '' && this.propSessionId !== undefined) {
        this.getSessionInfo()
      }
    },
    selectedPlatform() {
      if(this.selectedPlatform !== null) {
        this.currentGames = this.allGames.filter(game => {
          const platformIds = game.platforms.map(p => p.id)
          if(platformIds.includes(this.selectedPlatform)) return true
          else return false
        })
        const gameIds = this.currentGames.map(p => p.id)
        if(!gameIds.includes(this.selectedGame)) {
          this.selectedGame = null
        }
      } else {
        this.currentGames = [...this.allGames]
      }
    },
  },
  async mounted() {
    await this.getAllGames()
    await this.getPlatforms()

    if(this.propSessionId !== '' && this.propSessionId !== undefined) {
      await this.getSessionInfo()
    }
    if (this.isPlatformUser) {
      this.selectedPlatform = this.allPlatforms.find(plat => plat.name == this.loggedUser.attributes.platform[0]).id
    }
    this.ready = true
  },
  computed: {
    loggedUser() {
      return this.$store.getters['user/user']
    },
    isPlatformUser() {
      return Object.keys(this.loggedUser.attributes).length > 0
    },
  },
  methods: {
    removeMedia(index) {
      this.mediasToUpload.splice(index, 1);
    },
    downloadFile(url) {
      window.open(url);
    },
    updateDescription(value) {
      this.ticketDescription = value;
    },
    handleFileUpload() {
      for (let i = 0; i < this.$refs.attachments.files.length; i++) {
        const file = this.$refs.attachments.files[i];
        const fileSrc = URL.createObjectURL(file);
  
        this.mediasToUpload.push({
          file: file,
          name: file.name,
          url: fileSrc
        });
      }
    },
    checkErros() {
      if (this.ticketTitle === '') {
        this.$toast.error('Please enter a title.')
        return false
      }
      if (this.ticketDescription === '') {
        this.$toast.error('Please enter a description.')
        return false
      }
      return true
    },
    async getSessionInfo() {
      try {
        const session = (await request.get (`/bookkeeping/session/${this.propSessionId}`)).data
        this.session = session
        this.sessionId = session.aamsTicketId
        console.log(session.game.id)
        this.selectedGame = session.game.id
        this.selectedPlatform = session.platform.id
      } catch (e) {
        console.log(e.message)
        this.$toast.error('Something went wrong getting session, please try again.')
      }
    },
    async getPlatforms() {
      try {
        if(this.isPlatformUser) {
          const userPlatform = (await request.get(`/platforms/byName/${this.loggedUser.attributes.platform[0]}`)).data
          this.allPlatforms = [userPlatform]
        } else {
          const platforms = (await request.get('/platforms?with=games')).data.rows
          this.allPlatforms = platforms
          this.allPlatforms.sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (e) {
        console.log(e);
        this.serverError = true;
        this.$toast.error('Something went wrong getting platforms, please try again.')
      }
    },
    async getAllGames() {
      try {
        let games = (await request.get('/catalog?with=platforms')).data.rows
          .filter(game => !game.isDev)
          .map(game => {
            return {
              ...game,
              name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name
            }
          })

        this.allGames = [...games];
        this.currentGames = [...games]

      } catch (e) {
        console.log(e);
        this.serverError = true;
        this.$toast.error('Something went wrong getting games, please try again.')
      }
    },
    async submitMedia(ticketId) {
      try {
        await Promise.all(
          this.mediasToUpload.map((media) => {
            let formData = new FormData();
            formData.append("file", media.file);
  
            return request.post(`/tickets/${ticketId}/media`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          })
        )
      } catch (e) {
        console.log(e);
        this.serverError = true;
        this.$toast.error('Something went wrong submitting media, please try again.')
      }
    },
    async createTicket() {
      if (!this.checkErros()) return;
      try {
        const newTicket = (await request.post('/tickets', {
          title: this.ticketTitle,
          description: this.ticketDescription,
          reporterId: this.loggedUser.userId,
          platformId: this.selectedPlatform,
          sessionId: this.sessionId !== '' ? this.session.id : null,
          gameId: this.selectedGame
        })).data
        await this.submitMedia(newTicket.id)
        this.$toast.success('Ticket created')
        this.$router.push({name: 'Tickets'})
      } catch (e) {
        console.log(e.message)
        this.$toast.error('Something went wrong creating ticket, please try again.')
      }
    },
  }
}
</script>
<style scoped>

</style>
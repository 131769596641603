<template>
  <div
    class="w-full h-full min-h-full flex flex-col items-center gap-4 bg-zinc-950 relative z-[10] pb-[55px] xl:pb-0"
    :class="displayType ==='create' ? 'h-max' : 'h-full'"
  >
    <!-- header  -->
    <div class="flex justify-between gap-6 w-full px-4 md:px-7 pt-4 pb-4">
      <h2 class="text-white text-3xl xl:text-4xl fugaz uppercase pt-0.5">
        {{ $t(`games.${displayType}`) }} 
        <span v-if="displayType ==='manage'">games</span>
        <span v-if="displayType ==='create'">new game</span>
      </h2>
      <div class="flex items-center gap-3">
        <button @click="displayType = 'manage'" class="hover:text-pink-600 text-lg xl:text-xl fugaz uppercase transition-all duration-150"
          :class="displayType ==='manage' ? 'text-pink-600' : 'text-zinc-300'"
        >
          {{ $t('games.manage') }}
        </button>
        <button @click="displayType = 'create'" class="hover:text-pink-600 text-lg xl:text-xl fugaz uppercase transition-all duration-150"
          :class="displayType ==='create' ? 'text-pink-600' : 'text-zinc-300'"
        >
          {{ $t('games.create') }}
        </button>
      </div>
    </div>
    <ManageGames v-if="ready && displayType === 'manage'" :games="catalogGames" />
    <div v-if="ready && displayType === 'create'"  class="h-full w-full flex flex-col justify-between">
      <div class="w-full z-[20]">
        <div
          class="w-full flex items-center justify-center bg-zinc-900 fugaz uppercase"
        >
          <div
            class="w-full border-r border-zinc-800 flex justify-center cursor-pointer text-white py-1 text-sm md:text-lg"
            :class="
              currentStepNumber == 1 ? 'bg-pink-600' : 'bg-zinc-900'
            "
            @click="goToStep(1)"
          >
            {{ $t('games.name') }}
          </div>
          <div
            class="w-full border-r border-zinc-800 flex justify-center cursor-pointer text-white py-1 text-sm md:text-lg"
            :class="
              currentStepNumber == 2 ? 'bg-pink-600' : 'bg-zinc-900'
            "
            @click="goToStep(2)"
          >
            {{ $t('games.media') }}
          </div>
          <div
            class="w-full border-r border-zinc-800 flex justify-center cursor-pointer text-white py-1 text-sm md:text-lg"
            :class="
              currentStepNumber == 3 ? 'bg-pink-600' : 'bg-zinc-900'
            "
            @click="goToStep(3)"
          >
            {{ $t('games.info') }}
          </div>
          <div
            class="w-full flex justify-center cursor-pointer text-white py-1 text-sm md:text-lg"
            :class="
              currentStepNumber == 4 ? 'bg-pink-600' : 'bg-zinc-900'
            "
            @click="goToStep(4)"
          >
            {{ $t('games.preview') }}
          </div>
        </div>
      </div>
      <div
        v-if="currentStepNumber > 1 && fromStorage"
        class="hidden md:block absolute top-0 left-0 right-0 mr-auto ml-auto pt-3.5 pb-1.5 rounded-sm text-white w-max rounded-b-lg z-50"
      >
        {{ $t('games.based') }} <span class="fugaz uppercase">{{ slug }}</span>
      </div>
      <StepOne
        v-show="currentStepNumber == 1"
        :goToStep="goToStep"
        :nextStep="nextStep"
      />
  
      <StepTwo v-if="currentStepNumber == 2" :goToStep="goToStep" />
  
      <StepThree v-if="currentStepNumber == 3" :goToStep="goToStep" />
  
      <StepFour v-if="currentStepNumber == 4" :goToStep="goToStep" />

    </div>
    <div
      v-if="!ready"
      class="w-full h-[90vh] flex justify-center items-center bg-zinc-950 text-white"
    >
      <img v-if="!serverError" src="../../assets/gai-logo-full.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
      <div v-else>
        {{ $t('serverError') }}
        <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
        {{ $t('tryAgain') }}
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/services/axios";
import StepOne from "../../components/games/StepOne.vue";
import StepTwo from "../../components/games/StepTwo.vue";
import StepThree from "../../components/games/StepThree.vue";
import StepFour from "../../components/games/StepFour.vue";
import ManageGames from "../../components/games/ManageGames.vue";

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    ManageGames,
  },
  props: {
    stepNumber: String,
  },
  data() {
    return {
      externalIdentifier: null,
      docsFile: {},
      assetsFile: {},
      serverError: false,
      catalogGames: [],
      displayType: 'manage',
      ready: false,
      mountTimer: null,
    };
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      this.catalogGames = (await request.get('/catalog?with=platforms')).data?.rows;
      this.$store.dispatch('newGame/setLoadingAction', false)
      this.$router.push("/games/1");
      window.addEventListener('beforeunload', this.resetForm);
      this.ready = true;
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
    window.removeEventListener('beforeunload', this.resetForm);
    this.resetForm()
  },
  computed: {
    // loading() {
    //   return this.$store.getters["newGame/loading"];
    // },
    currentStepNumber() {
      return parseInt(this.$route.params.stepNumber);
    },
    fromStorage() {
      return this.$store.getters["newGame/fromStorage"];
    },
    name() {
      return this.$store.getters["newGame/basicInfo"].name;
    },
    sub_name() {
      return this.$store.getters["newGame/basicInfo"].sub_name;
    },
    slug() {
      return this.$store.getters["newGame/basicInfo"].slug;
    },
    imagesToUpload() {
      return this.$store.getters["newGame/imagesToUpload"];
    },
    gameDetails() {
      return this.$store.getters["newGame/gameDetails"];
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
  },
  methods: {
    resetForm() {
      // this.$store.dispatch('newGame/resetFormAction')
    },
    errorMessage(message) {
      this.$toast.error(message, {
        position: "top-right",
        autoClose: 3000,
      });
    },
    checkErrors(step) {
      let errors = [];
      if (!this.name) {
        errors.push("Name is required");
      }
      if (!this.slug) {
        errors.push("Slug is required");
      }
      else {
        const catalogSlugs = this.catalogGames.map((game) => game.slug);
        if(catalogSlugs.includes(this.slug)) {
          errors.push("Slug already exists");
        }
        if (this.slug.includes(' ')) {
          errors.push("Slug cannot contain spaces");
        }
      }
      return errors;
    },
    goToStep(stepNumber) {
      if (this.serverError) {
        this.errorMessage("Server error, reload to try again");
      } else {
        this.emitter.emit(`stepNumber${this.currentStepNumber}Called`);

        const errors = this.checkErrors(stepNumber);
        for (let i = 0; i < errors.length; i++) {
          this.$toast.error(errors[i], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (!errors.length) {
          this.$router.push("/games/" + stepNumber);
          window.scrollTo(0, 0);
        }
      }
    },
    nextStep(e) {
      e.preventDefault();
      window.scrollTo(0, 0);
      this.emitter.emit(`stepNumber${this.currentStepNumber}Called`);
      if (this.currentStepNumber < 4) this.goToStep(this.currentStepNumber + 1);
    },
    previousStep(e) {
      e.preventDefault();
      window.scrollTo(0, 0);
      if (this.currentStepNumber > 1) this.goToStep(this.currentStepNumber - 1);
    },
  },
};
</script>
<style></style>

<template>
  <div @click="openOrCloseCharts('games', false)" 
    class="w-full h-full flex justify-center items-center bg-black bg-opacity-75 fixed top-0 left-0 z-[9999]">
    <div @click.stop class="bg-zinc-800 flex flex-col gap-2.5 rounded-md relative px-5 pt-5 pb-12" style="height: 80%; width: 75%;">
      <button @click.stop="openOrCloseCharts('games', false)" class="absolute top-2.5 right-3">
        <font-awesome-icon :icon="['fas', 'xmark']" class="h-6 text-zinc-300" />
      </button>
      <h1 class="text-white fugaz uppercase text-2xl">
        Game charts
      </h1>
      <div class="w-full h-full flex justify-center">
        <Bar
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)  

  export default {
    data() {
      const gamesWithInfo = this.games.filter(game => (game.sessionsCount > 0 || game.grossWin !== 0))
      gamesWithInfo.sort((a, b) => b.sessionscount - a.sessionscount)
      const gameNames = gamesWithInfo.map(game => game.name.toUpperCase());
      const gameGrossWins = gamesWithInfo.map(game => game.grossWin)
      const gameSessions = gamesWithInfo.map(game => game.sessionsCount)

      return {
        chartData: {
          labels: gameNames,

          datasets: [
            {
              label: 'Net win',
              backgroundColor: 'rgb(219 39 119)',
              data: gameGrossWins
            },
            {
              label: 'Sessions',
              backgroundColor: 'rgb(228 228 231)',
              data: gameSessions
            }
          ]
        },
        chartOptions: {
          responsive: true,
          plugins: {
            legend: {
              labels: {
                color: 'white' // Change label text color here
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: 'white', // Change scale label (number) color here
                font: {
                  family: 'Fugaz One, cursive', // Change font-family here
                  weight: 'medium',
                  size: 12, // Adjust font size as needed
                }
              },
              grid: {
                color: 'rgb(82 82 91)',
                borderColor: 'green'
              }
            },
            x: {
              ticks: {
                color: 'white', // Change scale label (number) color here
                font: {
                  family: 'Fugaz One, cursive', // Change font-family here
                  weight: 'medium',
                  size: 12, // Adjust font size as needed
                }
              },
              grid: {
                color: 'rgb(82 82 91)',
                borderColor: 'green'
              }
            }
          }
        }

      }
    },
    components: { Bar },
    props: {
      openOrCloseCharts: Function,
      games: Array,
    },

  }
</script>

<style scoped>
.chartjs-render-monitor .chart-legend li span {
  color: white !important;
}
.chartjs-render-monitor .chartjs-chart {
  text-transform: uppercase; /* Change text to uppercase */
}
</style>
<template>
  <div
    class="w-full h-max min-h-full flex flex-col justify-start items-start bg-zinc-950 pb-[50px] xl:pb-0"
  >
    <div class="w-full h-full flex flex-col items-center justify-center">
      <div
        class="flex pt-4 pb-5 transition-all duration-100 px-4 md:px-7"
        :class="sidebarFixed ? 'w-[100%]' : 'w-[100%]'"
      >
        <h2 class="text-3xl xl:text-4xl fugaz uppercase text-white">
          {{ $t("dashboard.title") }}
        </h2>
      </div>
      <div
        v-if="ready"
        class="flex w-full flex-wrap justify-center px-4 md:px-7"
      >
        <div
          class="flex flex-col transition-all duration-100"
          :class="sidebarFixed ? 'w-[100%]' : 'w-[100%]'"
        >
          <h3
            class="text-xl text-white roboto font-thin mb-2 flex items-center"
          >
            <!-- <font-awesome-icon :icon="['fas', 'money-bill-trend-up']" class="pr-2 pt-0.5 text-green-600" /> -->
            {{ $t("dashboard.chartTitle") }}
            <img
              src="../assets/euros.png"
              alt="euros icon"
              class="pl-2 pt-1.5 animate-pulse h-8"
            />
          </h3>
          <div class="flex flex-col">
            <div
              class="flex items-center justify-between bg-zinc-900 rounded-t-md pt-2 pb-1 px-2.5 border-b border-zinc-800"
            >
              <Values
                :loading="loadingTotals"
                :allBet="allBet"
                :allWin="allWin"
                :grossWin="allGrossWin"
              />
              <!-- <router-link :to="{ name: 'Charts'}" class="text-white fugaz uppercase pr-2 transition-all duration-200 xl:hover:text-pink-600">
                Charts
              </router-link> -->
            </div>
            <div
              class="w-full h-[250px] md:h-[350px] bg-zinc-900 rounded-b-md px-1 md:px-4 pb-1 md:pb-4"
            >
              <div
                v-if="loadingTotals"
                class="w-full h-full flex items-center justify-center gap-4"
              >
                <img
                  src="../assets/gai-logo-full.png"
                  alt="gai hub logo"
                  class="h-20 w-auto animate-pulse"
                />
                <!-- <img src="../assets/loading2.png" alt="loading icon"
                  class="text-zinc-400 h-[50px] animate-spin mb-1.5"
                /> -->
              </div>
              <Line
                v-else
                :data="linesChartData"
                :options="linesChartOptions"
              />
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center gap-2 w-full group mt-4 mb-4 md:mb-2"
        >
          <div class="flex flex-wrap items-center gap-3.5">
            <router-link
              :to="{ name: 'Sessions' }"
              class="md:fugaz md:uppercase font-thin md:font-normal text-2xl md:text-base text-white xl:hover:text-pink-600 text-left transition-all duration-100"
            >
              {{ $t("dashboard.sessions") }}
            </router-link>
            <div class="flex flex-wrap items-center justify-center gap-3.5">
              <div
                class="bg-zinc-900 text-white pb-1.5 pt-2 px-3 fugaz uppercase flex justify-between items-center gap-2 rounded-md"
              >
                <div>{{ $t("dashboard.all") }}</div>
                <div class="text-lg pr-1 text-zinc-300">
                  {{ sessionsCount.all }}
                </div>
              </div>
              <div
                class="bg-zinc-900 text-white pb-1.5 pt-2 px-3 fugaz uppercase flex justify-between items-center gap-2 rounded-md"
              >
                <div>{{ $t("dashboard.open") }}</div>
                <div class="text-lg pr-1 text-blue-500">
                  {{ sessionsCount.open }}
                </div>
              </div>
              <div
                class="bg-zinc-900 text-white pb-1.5 pt-2 px-3 fugaz uppercase flex justify-between items-center gap-2 rounded-md"
              >
                <div>{{ $t("dashboard.closed") }}</div>
                <div class="text-lg pr-1 text-green-500">
                  {{ sessionsCount.closed }}
                </div>
              </div>
              <div
                class="bg-zinc-900 text-white pb-1.5 pt-2 px-3 fugaz uppercase flex justify-between items-center gap-2 rounded-md"
              >
                <div>{{ $t("dashboard.players") }}</div>
                <div class="text-lg pr-1 text-white">
                  {{ sessionsCount.players }}
                </div>
              </div>
              <button
                @click="initialize"
                :disabled="loadingTotals"
                class="fugaz uppercase text-white xl:hover:text-pink-600 transition-all duration-100 flex items-center gap-1.5 disabled:pointer-events-none"
              >
                <font-awesome-icon
                  :icon="['fas', 'arrows-rotate']"
                  :class="loadingTotals && 'animate-spin'"
                />
                <span class="pt-0.5">{{ $t("dashboard.refresh") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center h-[260px] w-full">
          <h1 class="text-2xl text-white roboto font-thin text-left pb-1.5">
            {{ $t("dashboard.topGames") }}
          </h1>
          <div
            v-if="loadingTotals"
            class="flex flex-col gap-3 w-full h-full bg-zinc-900"
          >
            <div class="flex items-center justify-center gap-4 w-full h-full">
              <img
                src="../assets/gai-logo-full.png"
                alt="gai hub logo"
                class="h-20 w-auto animate-pulse"
              />
              <!-- <img src="../assets/loading2.png" alt="loading icon"
                class="text-zinc-400 h-[50px] animate-spin mb-1.5"
              /> -->
            </div>
          </div>
          <div v-else class="flex flex-col gap-3">
            <component
              v-for="(game, index) in topThreeGames"
              :is="game.isCatalog ? 'router-link' : 'span'"
              :to="{ name: 'Game', params: { id: game.id } }"
              class="bg-zinc-900 px-3 py-1.5 rounded-sm relative text-white min-h-[70px] bg-no-repeat flex justify-end"
              :class="game.isCatalog && 'hover:brightness-110'"
              :style="`${getMiniBannerBackground(game)}`"
            >
              <div class="w-max flex items-center">
                <div class="hidden md:block flex-shrink rounded-md">
                  <Values
                    :allBet="game.totalBet"
                    :allWin="game.totalWin"
                    :grossWin="game.totalGrossWin"
                  />
                </div>
              </div>
              <span
                class="absolute bottom-0 top-0 left-0 right-0 md:right-64 lg:right-0 m-auto w-max h-max px-1.5 pt-0.5 rounded-sm bg-zinc-900 bg-opacity-50 fugaz uppercase text-xl"
              >
                {{
                  game.sub_name ? `${game.name} - ${game.sub_name}` : game.name
                }}
              </span>
            </component>
          </div>
        </div>
        <div class="w-full mt-4 mb-4">
          <h1 class="text-2xl text-white roboto font-thin text-left pb-1.5">
            {{ $t("dashboard.profitOverview") }}
          </h1>
          <table class="w-full border-separate border-spacing-y-[3px]">
            <thead class="bg-zinc-800 border-zinc-700">
              <tr class="text-white text-left fugaz uppercase">
                <th class="p-2">{{ $t("dashboard.timePeriod") }}</th>
                <th class="p-2">Bet</th>
                <th class="p-2 hidden md:table-cell">Win</th>
                <th class="p-2 hidden md:table-cell">Gross Win</th>
              </tr>
            </thead>
            <tbody
              v-if="!loadingLastMonths"
              class="bg-zinc-900 fugaz uppercase"
            >
              <tr class="text-white">
                <td class="p-2">{{ $t("dashboard.yesterday") }}</td>
                <td class="p-2 text-sky-600">
                  {{ this.getCurrencyValue(this.yesterdayAllBet) }}
                </td>
                <td class="p-2 hidden md:table-cell text-pink-600">
                  {{ this.getCurrencyValue(this.yesterdayAllWin) }}
                </td>
                <td
                  class="p-2 hidden md:table-cell"
                  :class="
                    this.yesterdayGrossWin >= 0
                      ? 'text-emerald-600'
                      : 'text-red-500'
                  "
                >
                  {{ this.getCurrencyValue(this.yesterdayGrossWin) }}
                </td>
              </tr>
              <tr class="text-white">
                <td class="p-2">{{ $t("dashboard.thisMonth") }}</td>
                <td class="p-2 text-sky-600">
                  {{ this.getCurrencyValue(this.thisMonthAllBet) }}
                </td>
                <td class="p-2 hidden md:table-cell text-pink-600">
                  {{ this.getCurrencyValue(this.thisMonthAllWin) }}
                </td>
                <td
                  class="p-2 hidden md:table-cell"
                  :class="
                    this.thisMonthGrossWin >= 0
                      ? 'text-emerald-600'
                      : 'text-red-500'
                  "
                >
                  {{ this.getCurrencyValue(this.thisMonthGrossWin) }}
                </td>
              </tr>
              <tr class="text-white">
                <td class="p-2">{{ $t("dashboard.lastMonth") }}</td>
                <td class="p-2 text-sky-600">
                  {{ this.getCurrencyValue(this.lastMonthAllBet) }}
                </td>
                <td class="p-2 hidden md:table-cell text-pink-600">
                  {{ this.getCurrencyValue(this.lastMonthAllWin) }}
                </td>
                <td
                  class="p-2 hidden md:table-cell"
                  :class="
                    this.lastMonthGrossWin >= 0
                      ? 'text-emerald-600'
                      : 'text-red-500'
                  "
                >
                  {{ this.getCurrencyValue(this.lastMonthGrossWin) }}
                </td>
              </tr>
              <tr class="text-white">
                <td class="p-2">{{ $t("dashboard.last3Months") }}</td>
                <td class="p-2 text-sky-600">
                  {{ this.getCurrencyValue(this.lastThreeMonthsAllBet) }}
                </td>
                <td class="p-2 hidden md:table-cell text-pink-600">
                  {{ this.getCurrencyValue(this.lastThreeMonthsAllWin) }}
                </td>
                <td
                  class="p-2 hidden md:table-cell"
                  :class="
                    this.lastThreeMonthsGrossWin >= 0
                      ? 'text-emerald-600'
                      : 'text-red-500'
                  "
                >
                  {{ this.getCurrencyValue(this.lastThreeMonthsGrossWin) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="loadingLastMonths"
            class="w-full h-full flex justify-center items-center bg-zinc-950 text-zinc-400"
          >
            <div class="flex items-center gap-2">
              <img
                src="../assets/gai-logo-full.png"
                alt="gai hub logo"
                class="h-24 w-auto animate-pulse"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full h-[90vh] flex justify-center items-center bg-zinc-950 text-zinc-400 mt-4"
      >
        <div v-if="!serverError" class="flex items-center gap-2">
          <img
            src="../assets/gai-logo-full.png"
            alt="gai hub logo"
            class="h-24 w-auto animate-pulse"
          />
        </div>
        <div v-if="serverError">
          Error trying to connect to the server.
          <button @click="$router.go()" class="text-blue-600">Reload</button>
          page to try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../services/axios";
import Values from "../components/dashboard/Values.vue";
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import { Line, Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "DashboardView",
  data() {
    return {
      lastMaintenances: [],
      hoursArray: [],
      ready: false,
      serverError: false,
      loadingTotals: false,
      loadingLastMonths: false,
      chartLabels: [],
      betDataset: [],
      winDataset: [],
      grossWinDataset: [],
      playersDataset: [],
      totals: [],
      startDate: null,
      endDate: null,
      userPlatform: null,
      allBet: null,
      allWin: null,
      allGrossWin: null,
      yesterdayAllBet: null,
      yesterdayAllWin: null,
      yesterdayGrossWin: null,
      thisMonthAllBet: null,
      thisMonthAllWin: null,
      thisMonthGrossWin: null,
      lastMonthAllBet: null,
      lastMonthAllWin: null,
      lastMonthGrossWin: null,
      lastThreeMonthsAllBet: null,
      lastThreeMonthsAllWin: null,
      lastThreeMonthsGrossWin: null,
      servicesStatus: [
        { name: "gaming", status: null },
        { name: "play", status: null },
        { name: "launcher", status: null },
        { name: "data", status: null },
      ],
      sessionsCount: {
        all: null,
        open: null,
        closed: null,
        players: null,
      },
      topThreeGames: [],
      mountTimer: null,
    };
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      this.getToday();
      this.ready = true;
      this.initialize();
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
  },
  components: {
    Line,
    Doughnut,
    Values,
  },
  computed: {
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    screenWidth() {
      return this.$store.getters["getScreenWidth"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    linesChartOptions() {
      return {
        responsive: true,
        interaction: {
          mode: "index",
          intersect: false,
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.dataset.data[context.dataIndex];
                const datasetName = context.dataset.label;
                let label = this.getCurrencyValue(value);
                return `${datasetName}: ${label}`;
              },
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            ticks: {
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 15,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
          x: {
            ticks: {
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 15,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
        },
        maintainAspectRatio: false,
      };
    },
    linesChartData() {
      let datasets = [
        {
          label: `Bet`,
          borderColor: "rgb(2 132 199)",
          backgroundColor: "rgb(2 132 199)",
          data: this.betDataset,
          tension: 0.3,
        },
        {
          label: `Win`,
          borderColor: "rgb(219 39 119)",
          backgroundColor: "rgb(219 39 119)",
          data: this.winDataset,
          tension: 0.3,
        },
        {
          label: `GrossWin`,
          borderColor: "rgb(5 150 105)",
          backgroundColor: "rgb(5 150 105)",
          data: this.grossWinDataset,
          tension: 0.3,
        },
        // {
        //   label: `Players`,
        //   borderColor: 'rgb(255 255 255)',
        //   backgroundColor: 'rgb(255 255 255)',
        //   data: this.playersDataset,
        //   tension: 0.3,
        // },
      ];

      return {
        labels: this.chartLabels,
        datasets,
      };
    },
  },
  methods: {
    async initialize() {
      this.loadingTotals = true;
      this.loadingLastMonths = true;
      await this.getTotals(this.startDate, this.endDate, "totals");
      this.loadingTotals = false;
      await this.getTotals(this.startDate, this.endDate, "yesterday");
      await this.getTotals(this.startDate, this.endDate, "months");
      this.loadingLastMonths = false;
    },
    getMiniBannerBackground(game) {
      const image = game.game_media.find(
        (image) => image.isMiniBanner === true
      );
      if (image) {
        if (this.screenWidth > 640) {
          return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 33%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), url("${image.url}");`;
        } else {
          return `background-image: url("${image.url}");`;
        }
      } else {
        return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 33%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), rgba(24, 24, 27, 0));`;
      }
    },
    convertDateToIT(date) {
      // Create a Date object with your initial date
      const initialDate = date;

      // Convert the date to Italian timezone
      return new Date(
        initialDate.toLocaleString("en-US", { timeZone: "Europe/Rome" })
      );
    },
    checkDatesSameHour(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours()
      );
    },
    getToday() {
      const startDate = new Date();
      startDate.setHours(0, 0, 0);
      this.startDate = startDate;
      const endDate = new Date();
      endDate.setHours(23, 59, 59);

      this.endDate = endDate;
    },
    checkDatesSameMonth(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
      );
    },
    async getLastMaintenances() {
      const response = await request.get("/maintenances");
      this.lastMaintenances = response.data.reverse();
    },
    setValues(betKey, bet, winKey, win, grossWinKey) {
      this[betKey] = bet;
      this[winKey] = win;
      this[grossWinKey] = bet - win;
    },
    getTopThreeGamesBySum(bets, wins) {
      const gamesWithSum = bets.map((game) => {
        const totalBet = game.data.reduce((acc, num) => acc + num, 0);
        return { ...game, totalBet };
      });

      // Sort games by sum in descending order
      const sortedGames = gamesWithSum.sort((a, b) => b.totalBet - a.totalBet);

      const topThreeGames = sortedGames.slice(0, 3);
      for (const game of topThreeGames) {
        const gameWinData = wins.find((win) => win.label === game.label).data;
        game.totalWin = gameWinData.reduce((acc, num) => acc + num, 0);
        game.totalGrossWin = game.totalBet - game.totalWin;
      }
      return topThreeGames;
    },
    async getTotals(startDate, endDate, type) {
      this.loadingChart = true;
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (type === "yesterday") {
        start.setDate(start.getDate() - 1);
        end.setDate(end.getDate() - 1);
      }
      if (type === "months") {
        start.setMonth(start.getMonth() - 3);
      }
      try {
        const from = start;
        // console.log('from: ' + from)

        const to = end;
        // console.log('to: ' + to)
        // const gameIds = this.selectedGames.length > 0 ? this.selectedGames : null
        let platformIds = null;
        if (Object.keys(this.user.attributes).length > 0) {
          const platform = (
            await request.get(
              `/platforms/byName/${this.user.attributes.platform[0]}`
            )
          ).data;
          this.userPlatform = platform;
        }
        if (this.userPlatform) platformIds = [this.userPlatform.id];

        let stats = "bet,win,grosswin,netwin,due,rounds,sessionTotals";
        let groupDate = "";
        if (type === "totals") {
          groupDate = "%Y-%m-%d %H";
          stats += ",usersCount";
        }
        // console.log(stats)
        if (type === "yesterday") groupDate = "%Y-%m-%d";
        if (type === "months") groupDate = "%Y-%m";

        let sessionTypeId = 1;

        const requestObject = {
          from: useDateFormatter(from),
          to: useDateFormatter(to),
          platforms: platformIds,
          key: groupDate,
          stats,
          datasetTotals: 1,
          datasetGames: type === "totals" ? 1 : 0,
          sessionTypeId,
        };
        // console.log(requestObject)
        const a = btoa(JSON.stringify(requestObject));
        const response = (await request(`/bookkeeping/stats/?a=${a}`)).data;
        // console.log(response)

        if (type === "totals") {
          this.allBet = response.grandTotals.bet;
          this.allWin = response.grandTotals.win;
          this.allGrossWin =
            response.grandTotals.bet - response.grandTotals.win;

          this.sessionsCount.all = response.grandTotals.sessionsTotal;
          this.sessionsCount.open = response.grandTotals.sessionsOpen;
          this.sessionsCount.closed = response.grandTotals.sessionsClosed;
          this.sessionsCount.players = response.grandTotals.usersCount;

          function areDatesOnSameDay(dateArray) {
            // Check if the array has at least one date
            if (dateArray.length === 0) {
              return false;
            }

            // Extract the day part of the first date in the array
            const firstDateDay = dateArray[0].split(" ")[0];

            // Check if all other dates have the same day part
            return dateArray.every(
              (date) => date.split(" ")[0] === firstDateDay
            );
          }

          const formatFunction = (label) => {
            const labelSplitted = label.split(" ");
            const labelDate = new Date(labelSplitted[0]);
            labelDate.setHours(labelSplitted[1]);

            const options = { hour: "numeric", minute: "numeric" };

            if (!areDatesOnSameDay(response.labels)) {
              options.day = "numeric";
              options.month = "short";
            }

            return labelDate.toLocaleTimeString("en-GB", options);
          };

          const labels = response.labels.map(formatFunction);

          this.chartLabels = [...labels];

          // console.log(response.datasets.games.bet)
          const topThreeGames = this.getTopThreeGamesBySum(
            response.datasets.games.bet,
            response.datasets.games.win
          );
          const topThreeGamesIds = topThreeGames.map((game) => game.gameId);
          // console.log(topThreeGames)
          const games = (
            await request(
              `/games?gameIds=${topThreeGamesIds}&with=game_media&mediaTypes=mini`
            )
          ).data.rows;
          this.topThreeGames = topThreeGames.map((game) => {
            const fullGame = games.find((g) => g.id === game.gameId);
            const totalBet = game.totalBet;
            const totalWin = game.totalWin;
            const totalGrossWin = game.totalGrossWin;
            return { ...fullGame, totalBet, totalWin, totalGrossWin };
          });

          this.betDataset = response.datasets.totals.bet[0].data;
          this.winDataset = response.datasets.totals.win[0].data;
          this.grossWinDataset = response.datasets.totals.grosswin[0].data;
          // this.playersDataset = response.datasets.totals.usersCount[0].data
        }
        if (type === "yesterday") {
          this.setValues(
            "yesterdayAllBet",
            response.grandTotals.bet,
            "yesterdayAllWin",
            response.grandTotals.win,
            "yesterdayGrossWin"
          );
        }
        if (type === "months") {
          // totals 3 months
          this.setValues(
            "lastThreeMonthsAllBet",
            response.grandTotals.bet,
            "lastThreeMonthsAllWin",
            response.grandTotals.win,
            "lastThreeMonthsGrossWin"
          );
          const totals = response.datasets.totals;
          // totals this month
          this.setValues(
            "thisMonthAllBet",
            totals.bet[0].data[totals.bet[0].data.length - 1],
            "thisMonthAllWin",
            totals.win[0].data[totals.win[0].data.length - 1],
            "thisMonthGrossWin"
          );

          // totals last month
          this.setValues(
            "lastMonthAllBet",
            totals.bet[0].data[totals.bet[0].data.length - 2],
            "lastMonthAllWin",
            totals.win[0].data[totals.win[0].data.length - 2],
            "lastMonthGrossWin"
          );
        }
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
      this.loadingChart = false;
    },
    fullDate(createdAt) {
      const date = new Date(createdAt);
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return date.toLocaleDateString(this.appLanguage.long, options);
    },
  },
};
</script>

<style scoped>
tbody tr:nth-child(even) {
  background-color: rgb(34, 34, 34);
}
</style>

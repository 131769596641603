<template>
  <div 
    class="fixed top-0 left-0 flex justify-center items-center bg-black bg-opacity-75 z-[9999]"
    style="width: 100vw; height: 100vh;"
    @click="closeGallery"
    >
    <button @click.stop="previousImage(this.actualImage.index)" class="hidden md:block p-3 lg:p-5 text-xl md:text-2xl lg:text-3xl text-white xl:hover:text-pink-600">
      <font-awesome-icon :icon="['fas', 'angle-left']" />
    </button>
    <div class="relative flex flex-col gap-y-4 justify-center items-center bg-white dark:bg-transparent rounded-lg h-full"
      :class="sidebarFixed ? 'w-full md:w-[75%]' : 'w-full md:w-4/5'"
    >
      <div class="flex justify-center items-center w-[95%] lg:w-10/12 h-auto max-w-full max-h-[80%]">
        <img 
          @click.stop
          v-if="actualImage.url.includes('jpg') || actualImage.url.includes('png') || actualImage.url.includes('webp')" 
          :src="actualImage.url" alt="actual game image" class="w-full h-full object-contain"
        >
        <video @click.stop class="w-full h-full" style="max-width: 100%;" v-else :src="actualImage.url" autoplay controls></video>
      </div>
      
      <div ref="miniImages" class="flex items-center w-[95%] max-w-screen-xl px-1 pb-3 gap-x-2 md:gap-x-3 overflow-x-auto"
        :class="{'justify-center': !isScrollingNeeded}"
      >
        <div v-for="(image, index) in media" :key="`image${index}`" 
          :ref="`media${index}`"
          class="h-10 md:h-16 lg:h-20 w-auto cursor-pointer rounded-sm" 
          style="aspect-ratio: 13 / 13;"
          :class="image.url === actualImage.url && 'border-2 border-pink-600'"
          @click.stop="changeImage(image, index)">
          
          <img
            v-if="image.url.includes('jpg') || image.url.includes('png') || image.url.includes('webp')"
            class="w-full h-full object-cover rounded-sm"
            :src="image.url" 
            alt="mini img"
          >
          <div v-else 
          class="h-10 md:h-16 lg:h-20 w-auto bg-zinc-900 rounded-sm flex justify-center items-center relative" 
          >
            <video class="h-10 md:h-16 lg:h-20 w-auto z-[10] cursor-grab" :src="image.url"></video>
            <font-awesome-icon :icon="['fas', 'play']" 
              class="z-[20] absolute top-0 bottom-0 left-0 right-0 m-auto text-pink-600 h-6 md:h-8 lg:h-10 rounded" />
          </div>
        </div>
      </div>
    </div>
    <button @click.stop="nextImage(this.actualImage.index)" class="hidden md:block p-3 lg:p-5 text-xl md:text-2xl lg:text-3xl text-white xl:hover:text-pink-600">
      <font-awesome-icon :icon="['fas', 'angle-right']" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    media: Object
  },
  data() {
    return {
      isScrollingNeeded: false,
    }
  },
  computed: {
    screenWidth() {
      return this.$store.getters['getScreenWidth']
    },
    sidebarFixed() {
      return this.$store.getters['sidebar/getSidebarFixed']
    },
    actualImage() {
      return this.$store.getters['game/actualImage']
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscKey);
    this.$nextTick(() => {
      this.calculateIsScrollingNeeded();
      window.addEventListener('resize', this.calculateIsScrollingNeeded);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateIsScrollingNeeded);
  },
  methods: {
    calculateIsScrollingNeeded() {
      if (this.$refs.miniImages) {
        this.isScrollingNeeded =
          this.$refs.miniImages.scrollWidth > this.$refs.miniImages.clientWidth;
      }
    },
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.$store.dispatch('game/setShowGalleryAction', false)
      }
    },
    closeGallery(event) {
      // if (!event.target.closest('.child')) {
        this.$store.dispatch('game/setShowGalleryAction', false)
      // }
    },
    changeImage(image, index) {
      this.$store.dispatch('game/setActualImageAction', {
        url: image.url,
        index
      })
    },
    scrollToMedia(index) {
      const actualMedia = this.$refs[`media${index}`][0]
      actualMedia.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
      })
    },
    nextImage(index) {
      let newIndex = index + 1;
      if (index < this.media.length - 1) this.actualImage.index = newIndex;
      else newIndex = 0;

      this.scrollToMedia(newIndex);
      
      this.$store.dispatch('game/setActualImageAction', {
        url: this.media[newIndex].url,
        index: newIndex
      });
    },

    previousImage(index) {
      let newIndex = index - 1
      if(index > 0) this.actualImage.index = newIndex
      else newIndex = this.media.length - 1 

      this.scrollToMedia(newIndex);

      this.$store.dispatch('game/setActualImageAction', {
        url: this.media[newIndex].url,
        index: newIndex
      })
    }
  }
}
</script>

<style scoped>

</style>
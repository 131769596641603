<template>
  <div class="bg-zinc-950 relative shadow-md pt-6 w-full h-full min-h-full flex flex-col">
    <h1 class="text-3xl text-center text-white pl-8 fugaz uppercase">
      Basic info
    </h1>
    <div class="flex flex-col px-4 pt-4 h-full">
      <form class="flex flex-col items-center h-full" @submit.prevent="nextStep($event)">
        <div class="flex flex-col gap-3 items-center h-full">
          <div class="flex items-center justify-center flex-wrap gap-3 h-full">
            <!-- <div class="flex flex-col bg-zinc-800 p-2 rounded-md shadow-md relative">
              <label class="text-zinc-600 dark:text-white" for="slug">Slug:</label>
              <input
                v-model="slug"
                name="slug"
                type="text"
                placeholder="Type to search games"
                autocomplete="off"
                class="w-80 rounded-sm px-2 outline-none border-b"
              />
              <button v-if="slug.length" class="absolute right-2.5 bottom-1" @click.prevent="slug = ''">
                <font-awesome-icon :icon="['fas', 'xmark']" class="text-zinc-800 p-1" />
              </button>
            </div> -->
            <div class="w-full md:w-max bg-zinc-800 p-2 rounded-md shadow-md relative flex flex-col" :class="typeOfSlug === 'select' && 'min-w-[330px]'">
              <label class="text-zinc-600 dark:text-white" for="slug">Slug</label>
              <div v-if="typeOfSlug === 'select'" class="w-full">
                <Multiselect
                  mode="single"
                  v-model="slug"
                  :groups="true"
                  :options="{
                    label: 'Games from storage:',
                    options: outsideGames
                  }"
                  :placeholder="'Type to search'"
                  :loading="!ready"
                  :close-on-select="true"
                  :searchable="true"
                  label="slug"
                  value-prop="slug"
                  ref="multiselect"
                />
              </div>
              <input v-else name="slug" type="text" v-model="slug" 
                class="w-full min-w-[315px] rounded-md px-3.5 py-2 bg-zinc-900 text-white outline-none"
                placeholder="Enter new slug"
              >
              <div class="flex items-center justify-center gap-2 absolute top-1.5 right-3">
                <button @click="typeOfSlug = 'new'" type="button" 
                  class="text-sm rounded-sm xl:hover:text-pink-600 fugaz uppercase transition-all duration-100"
                  :class="typeOfSlug === 'new' ? 'text-pink-600' : 'text-white'"
                >
                  New
                </button>
                <button @click="typeOfSlug = 'select'" type="button" 
                  class="text-sm rounded-sm xl:hover:text-pink-600 fugaz uppercase transition-all duration-100"
                  :class="typeOfSlug === 'select' ? 'text-pink-600' : 'text-white'"
                >
                  From storage
                </button>
              </div>
            </div>
            <div class="flex flex-col bg-zinc-800 p-2 rounded-md shadow-md w-full md:max-w-[320px]">
              <label class="text-zinc-600 dark:text-white" for="name">Name </label>
              <input
                v-model="name"
                name="name"
                type="text"
                autocomplete="off"
                class="w-full min-w-80 rounded-md px-2 py-2 bg-zinc-900 text-white outline-none"
              />
            </div>
            <div class="flex flex-col bg-zinc-800 p-2 rounded-md shadow-md w-full md:max-w-[320px]">
              <label class="text-zinc-600 dark:text-white" for="name">Sub name </label>
              <input
                v-model="sub_name"
                name="name"
                type="text"
                autocomplete="off"
                class="w-full min-w-80 rounded-md px-2 py-2 bg-zinc-900 text-white outline-none"
              />
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-col items-center my-4 text-zinc-600 dark:text-white">
          <h2 class="text-xl font-semibold mb-3">Games on storage:</h2>
          <div v-if="ready">
            <div
              v-if="!serverError"
              class="flex flex-wrap items-center justify-center gap-2"
            >
              <div
                v-for="(game, index) in outsideGames.filter((game) =>
                  game.includes(slug)
                )"
                :key="`out${index}`"
                class="text-lg px-3 rounded-md cursor-pointer w-max text-zinc-200 border-zinc-500 bg-zinc-800 xl:hover:text-white"
                :class="slug === game && fromStorage ? 'border-4' : ''"
                @click="setGameFromStorage(game)"
              >
                {{ game }}
              </div>
            </div>
            <div v-else class="p-4">
              <div class="text-black dark:text-zinc-300">
                Error fetching server,
                <button @click="$router.go()" class="text-blue-600">reload</button>
                page to try again.
              </div>
            </div>
          </div>
          <div v-else>
            <img src="../../assets/loading2.png" alt="loading icon"
              class="text-zinc-600 dark:text-zinc-400 h-7 animate-spin"
            />
          </div>
        </div> -->
        <!-- <button
          @click="nextStep($event)"
          class="my-6 py-1 px-5 rounded-sm flex items-center gap-2 justify-center text-zinc-200 xl:hover:text-white "
        >
          <span class="fugaz uppercase -mb-0.5">Next step</span>
          <font-awesome-icon :icon="['fas', 'forward']" class="h-5" />
        </button> -->
      </form>
    </div>
  </div>
</template>

<script>
import request from "../../services/axios";
import Multiselect from '@vueform/multiselect'

export default {
  name: "StepOne",
  props: {
    goToStep: Function,
    nextStep: Function,
  },
  components: {
    Multiselect
  },
  data() {
    return {
      ready: false,
      name: "",
      sub_name: "",
      slug: "",
      idFromDB: null,
      externalIdentifier: "",
      serverError: false,
      outsideGames: [],
      typeOfSlug: 'select',
    };
  },
  async created() {
    try {
      const actualGames = (await request.get("/games")).data?.rows;
      // console.log(actualGames)

      // const providerGames = (await request.get("/fetch/games")).data;
      // console.log(providerGames)

      // games that are not in gai hub but exist in game storage
      const games = [
        // ...providerGames.filter((slug) => !actualGames.find((g) => g.slug === slug)),
        ...actualGames.filter((g) => !g.isCatalog),
      ];

      // console.log(games);
      // this.outsideGames = [...new Set(games.map((slug) => slug))]; //get unique game slugs
      this.outsideGames = games;
      // console.log(this.outsideGames);

      this.outsideGames.sort((a, b) => a.slug.localeCompare(b.slug));
      // console.log(this.outsideGames);
      this.$store.dispatch("newGame/setLoadingAction", false);
      this.ready = true;
    } catch (error) {
      console.log(error);
      this.ready = true;
      this.serverError = true;
    }
    this.emitter.on("stepNumber1Called", this.setBasicInfo);
  },
  mounted() {
    if(Object.keys(this.basicInfo).length > 0 && this.basicInfo.name !== '') {
      // console.log(this.basicInfo);
      this.name = this.basicInfo.name
      this.sub_name = this.basicInfo.sub_name
      this.slug = this.basicInfo.slug
      this.typeOfSlug = 'new'
    }
  },
  computed: {
    fromStorage() {
      return this.$store.getters["newGame/fromStorage"];
    },
    basicInfo(){
      return this.$store.getters["newGame/basicInfo"];
    }
  },
  watch: {
    slug() {
      if (this.outsideGames.length) {
        const allGamesSlugs = this.outsideGames.map(g => g.slug);
        // console.log(allGamesSlugs)
        if (allGamesSlugs.includes(this.slug)) {
          const index = allGamesSlugs.indexOf(this.slug);
          this.setGameFromStorage(this.outsideGames[index]);
        } else {
          this.resetInfo();
        }
      }
      if(this.slug && this.typeOfSlug === 'select') {
        this.$refs.multiselect.blur()
      }
    },
    typeOfSlug() {
      this.slug = "";
      this.name = "";
      this.sub_name = "";
    }
  },
  methods: {
    setBasicInfo() {
      this.$store.commit("newGame/setBasicInfo", {
        slug: this.slug,
        name: this.name,
        sub_name: this.sub_name,
        idFromDB: this.idFromDB,
      });
    },
    setGameFromStorage(game) {
      // console.log(game)
      this.slug = game.slug;
      this.name = game.slug.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
      this.idFromDB = game.id

      this.$store.dispatch("newGame/setFromStorageAction", true);
    },
    resetInfo() {
      this.$store.dispatch("newGame/setFromStorageAction", false);
      // this.slug = "";
      this.name = "";
      this.sub_name = "";
      this.idFromDB = null
    },
  },
};
</script>

<style></style>

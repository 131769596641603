<template>
  <div
    class="relative bg-zinc-800 px-3 py-1.5 rounded-sm flex flex-col gap-1 w-full md:w-[49%]"
  >
    <button
      @click="changeEditing"
      class="absolute top-1 right-2 pl-1.5 xl:hover:text-pink-600 fugaz uppercase text-sm"
      :class="editing ? 'text-pink-600' : 'text-white'"
    >
      <!-- <font-awesome-icon :icon="['fas', 'pen-to-square']" /> -->
      Edit
    </button>
    <h3 class="pr-2 fugaz uppercase text-xl text-center">{{ serviceName }} service</h3>
    <div class="flex items-center" :class="!editing && 'justify-center'">
      <h3 class="font-semibold text-lg">URL:</h3>
      <h4 class="pl-1 text-lg" v-if="!editing">{{ fields.url }}</h4>
      <input
        v-else
        type="text"
        v-model="fields.url"
        @blur="checkUrlStatus(fields.url)"
        class="bg-zinc-900 outline-none pl-1 w-full text-lg"
      />
    </div>
    <div v-if="editing">
      <h3 class="font-semibold text-lg">New token:</h3>
      <h4 class="break-words text-lg px-1" v-if="!editing">
        {{ fields.token }}
      </h4>
      <textarea
        v-else
        v-model="fields.token"
        class="bg-zinc-900 outline-none px-1 resize-none w-full text-lg"
      />
    </div>
    <div v-if="!sureSave" class="flex justify-end">
      <button
        v-if="editing"
        @click="sureSave = true"
        class="px-3.5 py-0.5 rounded-sm uppercase fugaz mb-2 bg-pink-600 xl:hover:bg-pink-700"
      >
        save
      </button>
    </div>
    <div v-else class="flex justify-center items-center gap-2 mb-2">
      Are you sure? 
      <button
        @click="updateService"
        class="px-3.5 py-0.5 rounded-sm uppercase fugaz bg-green-600 xl:hover:bg-green-700"
      >
        yes
      </button>
      <button
        @click="sureSave = false"
        class="px-3.5 py-0.5 rounded-sm uppercase fugaz bg-red-600 xl:hover:bg-red-700"
      >
        no
      </button>
    </div>
    <div v-if="fields.url">
      <div v-if="status !== null && status !== 500">
        <div v-if="!loading" class="flex items-center justify-center gap-x-2">
          Status:
          <font-awesome-icon
            v-if="status === 200"
            :icon="['fas', 'circle-check']"
            class="text-green-500 h-5"
          />
          <font-awesome-icon
            v-else
            :icon="['fas', 'circle-xmark']"
            class="text-red-500 h-5"
          />
        </div>
        <div v-else class="flex items-center justify-center">
          <img src="../../assets/loading2.png" alt="loading icon" class="h-5 animate-spin" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import request from "../../services/axios";
export default {
  props: {
    platform: Object,
    serviceName: String,
    originalFields: Object,
  },
  data() {
    return {
      status: null,
      editing: false,
      fields: {
        url: this.originalFields.url,
        token: '',
      },
      loading: false,
      updates: {},
      sureSave: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },
  },
  created() {
    this.checkServiceStatus();
  },
  methods: {
    checkUpdates() {
      if(this.updates[`${this.serviceName}ServiceUrl`]) delete this.updates[`${this.serviceName}ServiceUrl`];
      if (this.originalFields.url !== this.fields.url) {
        this.updates[`${this.serviceName}ServiceUrl`] = {
          old: this.originalFields.url,
          new: this.fields.url
        }
      }
      if(this.updates[`${this.serviceName}ServiceToken`]) delete this.updates[`${this.serviceName}ServiceToken`]
      if (this.originalFields.token !== this.fields.token) {
        this.updates[`${this.serviceName}ServiceToken`] = {
          old: this.originalFields.token,
          new: this.fields.token
        }
      }
    },
    async checkUrlStatus(url) {
      this.loading = true
      try {
        const response = await axios.get(`${url}`)
        this.status = response.status
      } catch (e) {
        console.log(e)
        this.status = e.response?.status
      }
      this.loading = false
    },
    changeEditing() {
      if (this.editing) {
        this.editing = false;
        this.fields = {
          url: this.originalFields.url
        };
      } else this.editing = true;
    },
    async checkServiceStatus() {
      // console.log('checking service status');
      try {
        this.loading = true;
        const response = await request.get(
          `/platforms/${this.platform.id}/services/healthcheck`
        );
        const serviceStatus = response.data[`${this.serviceName}Service`]
          ? response.data[`${this.serviceName}Service`]
          : null;
        this.status = serviceStatus;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async updateService() {
      try {
        const {
          id,
          createdAt,
          updatedAt,
          providerId,
          games,
          users,
          maintenances,
          operators,
          ...platformFields
        } = this.platform;
        // console.log(platformFields);
        this.checkUpdates()
        if(Object.keys(this.updates).length === 0) {
          this.$toast.error(`Nothing to update`, {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
          });
          this.editing = false;
          this.fields.token = ''
          this.sureSave = false;
          return
        }
        await request.put(
          `/platforms/${this.platform.id}`,
          {
            ...platformFields,
            [`${this.serviceName}ServiceURL`]: this.fields.url,
            [`${this.serviceName}ServiceToken`]: this.fields.token,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        // request.post('/logs/create', {
        //   type: 2,
        //   description: `${this.user.username} UPDATED the platform "${this.platform.name}" (platformId: ${this.platform.id})`,
        //   updates: this.updates
        // })
        this.originalFields.url = this.fields.url;
        this.originalFields.token = this.fields.token;
        this.$toast.success(`${this.serviceName} service fields updated`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.editing = false;
        this.fields.token = ''
        this.sureSave = false;
        this.checkServiceStatus();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBackward,
  faBell,
  faChalkboardUser,
  faChartColumn,
  faChartLine,
  faCircle,
  faCircleCheck,
  faCircleHalfStroke,
  faCircleMinus,
  faCirclePlay,
  faCirclePlus,
  faCircleUser,
  faCircleXmark,
  faForward,
  faGear,
  faGrip,
  faHouse,
  faLaptop,
  faList,
  faMagnifyingGlass,
  faMobile,
  faMoon,
  faPenToSquare,
  faPlus,
  faRectangleList,
  faRightFromBracket,
  faSortDown,
  faSortUp,
  faSpinner,
  faTableCellsLarge,
  faUserGear,
  faUsersGear,
  faUsersRectangle,
  faXmark,
  faCheck,
  faBars,
  faChartPie,
  faChartBar,
  faCoins,
  faFileCsv,
  faEuroSign,
  faGift,
  faMoneyBillTrendUp,
  faThumbtack,
  faPlay,
  faGears,
  faLeftLong,
  faRightLong,
  faUpLong,
  faDownLong,
  faCopy,
  faSquareCaretUp,
  faCaretUp,
  faCaretDown,
  faEllipsisVertical,
  faEllipsis,
  faMinus,
  faFile,
  faDownload,
  faTrash,
  faArrowsRotate,
  faUsers,
  faUserPlus,
  faCircleExclamation,
  faEnvelope,
  faUpRightFromSquare,
  faPaperclip,
  faTicket,
  faBug,
} from "@fortawesome/free-solid-svg-icons";

import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons";

library.add(
  faHouse,
  faChartLine,
  faRectangleList,
  faUsersGear,
  faUsersRectangle,
  faCircleUser,
  faRightFromBracket,
  faUserGear,
  faMagnifyingGlass,
  faGrip,
  faTableCellsLarge,
  faList,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faCirclePlay,
  faCircle,
  faCircleHalfStroke,
  faLaptop,
  faMobile,
  faArrowUpRightFromSquare,
  faAngleLeft,
  faAngleRight,
  faSortUp,
  faSortDown,
  faMoon,
  faCirclePlus,
  faPlus,
  faCircleXmark,
  faXmark,
  faCheck,
  faSpinner,
  faCircleRegular,
  faBell,
  faCircleCheck,
  faCircleMinus,
  faGear,
  faPenToSquare,
  faForward,
  faBackward,
  faChalkboardUser,
  faChartColumn,
  faBars,
  faChartPie,
  faChartBar,
  faCoins,
  faFileCsv,
  faEuroSign,
  faGift,
  faMoneyBillTrendUp,
  faThumbtack,
  faPlay,
  faGears,
  faLeftLong,
  faRightLong,
  faUpLong,
  faDownLong,
  faCopy,
  faSquareCaretUp,
  faCaretUp,
  faCaretDown,
  faEllipsisVertical,
  faEllipsis,
  faMinus,
  faFile,
  faDownload,
  faTrash,
  faArrowsRotate,
  faUsers,
  faUserPlus,
  faCircleExclamation,
  faEnvelope,
  faUpRightFromSquare,
  faPaperclip,
  faTicket,
  faBug,
)

export default library